//material components
import Box from "@mui/material/Box";

//components
import NotificationMenu from "../Notification/NotificationMenu";
import ShopCar from "../ShopCar/ShopCar";
import SearchIconButton from "./SearchIconButton";
import UserMenu from "./UserMenu";

//react-redux
import { useSelector } from "react-redux";

const IconBar = () => {
    const user = useSelector((state) => state.user);

    return (
        <Box display="flex" gap="2px" alignItems="center">
            <SearchIconButton />
            {user && (
                <>
                    <NotificationMenu />
                    <ShopCar />
                </>
            )}
            <UserMenu />
        </Box>
    );
};

export default IconBar;
