import { useLanguage } from "../../../context/LanguageProvider";
import React, { Fragment, useContext, useState } from "react";
import { buyCrowdFunding } from "../../../actions";
import { CrowdFundingContext, CrowdFundingDispatchContext } from "../../../context/CrowdFundingConext";
import { BuyingContext, BuyingDispatchContext } from "../../../context/BuyingContext";
import {
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Typography,
    useTheme,
} from "@mui/material";
import {
    BUYING_ACTION,
    FINISHED_BUYING_ACTION,
    TYPE_CROWD_FUNDING,
    DELETE_SHOP_CAR_CROWD_FUNDING,
} from "../../../actions/constants";
import ItemShopCar from "../ItemShopCar";
import LocalMallOutlinedIcon from "@mui/icons-material/LocalMallOutlined";

const CrowdFundingTab = (props) => {
    const { languageState } = useLanguage();

    const [anchorEl, setAnchorEl] = useState(null);

    const context = useContext(CrowdFundingContext);
    const dispatch = useContext(CrowdFundingDispatchContext);

    const buying = useContext(BuyingContext);
    const dispatchBuying = useContext(BuyingDispatchContext);

    const theme = useTheme();

    const buyProductsCar = () => {
        dispatchBuying({
            type: BUYING_ACTION,
        });
        const data = {
            data: {
                tipo: TYPE_CROWD_FUNDING,
                proyectos: context.map((e) => {
                    return {
                        id: parseInt(e.id),
                        contribution: e.cant,
                        idcol: parseInt(localStorage.getItem("user_id")),
                    };
                }),
            },
        };
        buyCrowdFunding(data)
            .then((resp) => {
                window.location.replace(resp.data.stripeSession.url);
            })
            .catch((err) => console.log(err))
            .finally(() => {
                dispatchBuying({
                    type: FINISHED_BUYING_ACTION,
                });
            });
    };

    return (
        <>
            <Card sx={{ minWidth: 300, height: "100%", boxShadow: "none" }}>
                <CardContent
                    sx={{
                        padding: "5px",
                        overflow: "auto",
                        maxHeight: "100%",
                        paddingBottom: "52px",
                    }}
                >
                    {context?.map((e) => (
                        <ItemShopCar
                            key={e.id}
                            product={e}
                            actionDelete={DELETE_SHOP_CAR_CROWD_FUNDING}
                            contextAction={CrowdFundingDispatchContext}
                        />
                    ))}
                </CardContent>
                <CardActions
                    sx={{
                        minWidth: 300,
                        position: "fixed",
                        bottom: 0,
                        right: 0,
                        borderTopColor: "#fff",
                        borderTop: "solid",
                        borderTopWidth: "thin",
                        background: theme.palette.background.paper,
                    }}
                >
                    <Button
                        sx={{
                            width: "100%",
                            backgroundColor: "#f15a24",
                            color: "#fff",
                            "&:hover": {
                                transition: "transform 500ms ease !important",
                                backgroundColor: "rgba(241,90,36,0.45)",
                            },
                        }}
                        disabled={context?.length === 0 || buying}
                        onClick={buyProductsCar}
                        startIcon={<LocalMallOutlinedIcon />}
                    >
                        {buying
                            ? languageState.texts.ShopCar.button.loadingCrowdFunding
                            : languageState.texts.ShopCar.button.crowdFunding}
                    </Button>
                </CardActions>
            </Card>
        </>
    );
};

export default CrowdFundingTab;
