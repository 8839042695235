export const EVENT_TYPE_ID_CONCIERTO = 1;
export const EVENT_TYPE_ID_FESTIVAL = 2;
export const EVENT_TYPE_ID_TEATROS = 3;
export const EVENT_TYPE_ID_SALA = 4;

export const ENABLE_CHANGE_THEME = 1;
export const DISABLE_CHANGE_THEME = 0;
export const ONE_CHANGE_THEME = 2;

export const THEME_DARK = "dark";
export const THEME_LIGHT = "light";
