//@mui/material
import { Box, List } from "@mui/material";

//react
import React, { useEffect, useRef } from "react";

//component
import ConversationCloudFloatChat from "./ConversationCloudFloatChat";
import TypingAnimation from "./TypingAnimation";

const SpaceConversation = ({ messageChat, loading, scroll }) => {
    const messagesEndRef = useRef(null);

    const scrollToBottom = () => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
        }
    };

    useEffect(() => {
        scrollToBottom();
    }, [messageChat, scroll]);

    return (
        <Box
            sx={{
                width: "100%",
                height: "100%",
                overflowY: "scroll",
                overflowX: "none",
                scrollbarWidth: "thin",
            }}
        >
            <Box
                sx={{
                    width: "100%",
                    height: "100%",
                }}
            >
                <List>
                    {messageChat?.map((message, index) => {
                        const prevSender = index > 0 ? messageChat[index - 1].role : "";
                        const isSameSender = message.role === prevSender;
                        return (
                            <ConversationCloudFloatChat
                                key={index}
                                message={message}
                                isSameSender={isSameSender}
                            />
                        );
                    })}
                    {loading && (
                        <>
                            <TypingAnimation />
                        </>
                    )}
                    <div ref={messagesEndRef} />
                </List>
            </Box>
        </Box>
    );
};

export default SpaceConversation;
