//react-router
import { Outlet, useLocation, Navigate } from "react-router-dom";

//route-allowed-roles-mapping
import { RoleAccesByRoute } from "../../auth/security";

//react-redux
import { useSelector } from "react-redux";

const ProtectedRoutesWrapper = ({ redirectPath, deniedPath, definedPath }) => {
    const location = useLocation();
    const currentPath = definedPath ? definedPath : location?.pathname;
    const user = useSelector((state) => state.user);
    const userRole = user?.role_name;
    const isAllowed = RoleAccesByRoute[currentPath]?.includes(userRole);
    const isAuthenticated = !!user;

    if (!isAuthenticated) return <Navigate to={redirectPath} replace />;
    if (isAuthenticated && !isAllowed) return <Navigate to={deniedPath} replace />;

    return <Outlet />;
};

export default ProtectedRoutesWrapper;
