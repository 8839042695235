import axios from "axios";
import dayjs from "dayjs";
import { getFormatDate } from "../utils/functions";
var customParseFormat = require("dayjs/plugin/customParseFormat");
dayjs.extend(customParseFormat);

axios.defaults.headers.post["Content-Type"] = "application/json";

if (sessionStorage.getItem("token")) {
    axios.defaults.headers.post["Authorization"] = `Bearer ${sessionStorage.getItem("token")}`;
}

export const reloadHeader = () => {
    axios.defaults.headers.post["Authorization"] = `Bearer ${sessionStorage.getItem("token")}`;
};

export const reloadHeaderLogout = () => {
    axios.defaults.headers.post = {
        "Content-Type": "application/json",
        Accept: "application/json, text/plain */*",
    };
};

export const login = (credentials) => {
    return axios.post(`${process.env.REACT_APP_BASE_URL}/api/auth/local`, credentials);
};

export const register = (user) => {
    return axios.post(`${process.env.REACT_APP_BASE_URL}/api/auth/local/register`, user);
};

export const getNewsAdderTypes = () => {
    return axios.get(`${process.env.REACT_APP_BASE_URL}/api/news-adder-types`, {
        headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
    });
};

export const createTagNews = (id, tag) => {
    const payload = {
        data: {
            name: tag,
            entity_type: "news-adder",
            entity_id: id,
        },
    };
    return axios.post(`${process.env.REACT_APP_BASE_URL}/api/tags`, payload, {
        headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
    });
};

export const deleteTagNews = (id) => {
    const payload = {};
    return axios.post(`${process.env.REACT_APP_BASE_URL}/api/tags`, payload, {
        headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
    });
};

export const logout = () => {
    return axios.get(`${process.env.REACT_APP_BASE_URL}/logout`);
};

export const getUser = (userID) => {
    return axios.get(`${process.env.REACT_APP_BASE_URL}/api/users/${userID}?populate=role, image`);
};

export const getRoles = () => {
    return axios.get(`${process.env.REACT_APP_BASE_URL}/api/users-permissions/roles`);
};

export const getProductForCategoryPreview = () => {
    return axios.get(`${process.env.REACT_APP_BASE_URL}/api/category/getProductsByCategory`);
};

export const getProductsForCategoryById = (id) => {
    return axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/categories/${id}?populate[products][populate]=image`,
    );
};

export const getItemsOfCarousel = () => {
    return axios.get(`${process.env.REACT_APP_BASE_URL}/`);
};

export const getBuyForNow = () => {
    return axios.get(`${process.env.REACT_APP_BASE_URL}/api/bills_payment/create-session-check`);
};

export const buyProducts = (products) => {
    return axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/bills_payment/create-session-check`,
        products,
    );
};

export const buyTickets = (tickets) => {
    return axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/bills_payment/create-session-check`,
        tickets,
    );
};

export const buyCrowdFunding = (projects) => {
    return axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/bills_payment/create-session-check`,
        projects,
    );
};

export const getLastDisc = () => {
    return axios.get(`${process.env.REACT_APP_BASE_URL}/api/getLastDiscs/fiveDiscs`);
};

export const getProductById = (id) => {
    return axios.get(`${process.env.REACT_APP_BASE_URL}/api/products/${id}?populate=*`);
};

export const getPhotoArtistById = (id) => {
    return axios.get(`${process.env.REACT_APP_BASE_URL}/api/artists/${id}?populate=image`);
};

export const saveTokenFCM = (device) => {
    return axios.post(`${process.env.REACT_APP_BASE_URL}/api/auth/local/fcm`, {
        token: device,
    });
};

export const getProjectsCrowdfunding = () => {
    return axios.get(`${process.env.REACT_APP_BASE_URL}/api/project-crowdfundings?populate=*`);
};

export const getEntities = () => {
    return axios.get(`${process.env.REACT_APP_BASE_URL}/api/panel-entities?populate=*`, {
        headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
    });
};

export const getClasses = () => {
    return axios.get(`${process.env.REACT_APP_BASE_URL}/api/classes?populate=image,section,artist`, {
        headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
    });
};

export const saveTypeNews = (id, data) => {
    const payload = {
        news_adder_types: data,
    };
    return axios.post(`${process.env.REACT_APP_BASE_URL}/api/news-adders/update-type/${id}`, payload, {
        headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
    });
};

export const deleteTag = (id) => {
    return axios.delete(`${process.env.REACT_APP_BASE_URL}/api/tags/${id}`, {
        headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
    });
};

export const getCourses = () => {
    return axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/courses?populate=artist,miniature,subcategory,sections`,
        {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
        },
    );
};

export const getProductsHome = () => {
    return axios.get(`${process.env.REACT_APP_BASE_URL}/api/products?populate=image,categories`);
};

export const getItemsCarouselStore = () => {
    return axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/category/getProductsByCategory/lastProducts`,
    );
};

export const lastDiscs = () => {
    return axios.get(`${process.env.REACT_APP_BASE_URL}/api/getLastDiscs/fiveDiscs`);
};

export const getAlbuns = (filter) => {
    return axios.get(`${process.env.REACT_APP_BASE_URL}/api/albums?${filter}`);
};

export const getTopSelled = () => {
    return axios.get(`${process.env.REACT_APP_BASE_URL}/api/product/topselled`);
};
export const saveSection = (id, obj) => {
    return axios.put(
        `${process.env.REACT_APP_BASE_URL}/api/panel-cards/updatecardsbyposition/${id}`,
        obj,
        {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
        },
    );
};

export const getCountries = () => {
    return axios.get(`https://restcountries.com/v3.1/lang/spanish?fields=name,flags`);
};

export const getSections = () => {
    return axios.get(`${process.env.REACT_APP_BASE_URL}/api/panel-sections`);
};

export const getProjectsByType = (type) => {
    return axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/project-crowdfundings?filters[project_type]=${type}&populate=project_type,image,artist`,
    );
};

export const getFinishProjects = () => {
    const date = new Date();
    return axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/project-crowdfundings?populate=project_type,image,artist&sort[0]=final_date&filters[final_date][$gte]=${date.getFullYear()}-${date.getMonth() < 9 ? `0${date.getMonth()}` : date.getMonth() + 1}-${date.getDate()}`,
    );
};

export const getItemsSections = (id) => {
    return axios.get(`${process.env.REACT_APP_BASE_URL}/api/panel-cards/getCard/${id}`, {
        headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
    });
};
export const getItemsPositionSections = (id) => {
    return axios.get(`${process.env.REACT_APP_BASE_URL}/api/panel-cards/getcardsbyposition/${id}`);
};

export const getPanelPositionBySectionCode = async (key) => {
    const axiosResponse = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/panel-sections/getpositionbysectioneditorialcode/${key}`,
    );
    return axiosResponse;
};

export const getCardsByPanelPositionCode = async (key) => {
    const axiosResponse = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/panel-cards/getcardsbypositioncode/${key}`,
    );
    return axiosResponse;
};

export const getAllCardsByCode = async (key) => {
    const axiosResponse = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/panel-cards/getallcardsbypositioncode/${key}`,
    );
    return axiosResponse;
};

export const getCardsByPanelPosition = async (key) => {
    const [, panelPositionId] = key;
    const axiosResponse = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/panel-cards/getcardsbyposition/${panelPositionId}`,
    );

    if (axiosResponse.data[0]?.panel_cards) {
        const panelCards = axiosResponse.data[0].panel_cards.map((card) => ({
            id: card.id,
            item: card.item,
            panel_entity_id: card.panel_entity.id,
            panel_entity_name: card.panel_entity.name,
        }));

        return panelCards;
    }
    return [];
};

export const postCardsByPanelPosition = async (key, { arg }) => {
    const cardsToSend = arg.map((card) => ({ item: card.item, entity: card.panel_entity_id }));
    const [, panelPositionId, userToken] = key;
    const axiosResponse = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/panel-cards/updatePosition/${panelPositionId}`,
        { data: cardsToSend },
        {
            headers: {
                Authorization: `Bearer ${userToken}`,
            },
        },
    );

    if (axiosResponse.data) return axiosResponse.data;
    return [];
};

export const postNewsPreferences = async (tags, geo) => {
    try {
        const axiosResponse = await axios.post(
            `${process.env.REACT_APP_BASE_URL}/api/news-adders/my-preferences?sort[0]=updatedAt:desc&populate=*`,
            {
                tags: tags,
                geo: {
                    country_name: geo.country_name,
                    continent_code: geo.continent_code,
                    iso3: geo.country_code_iso3,
                },
            },
        );
        return axiosResponse;
    } catch (error) {
        console.error("Error posting news preferences:", error);
        throw error;
    }
};

export const getUserGeoInfo = async () => {
    try {
        const axiosResponse = await axios.get(`https://ipapi.co/json/`);
        return axiosResponse;
    } catch (error) {
        console.error("Error getting user geo info:", error);
        throw error;
    }
};

export const getPositionsSection = (sectionId) => {
    return axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/panel-sections/getPositionbySection/${sectionId}`,
    );
};

export const getPositionsBySectionId = async (key) => {
    const [, sectionId] = key;
    const axiosResponse = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/panel-sections/getPositionbySection/${sectionId}`,
    );

    return axiosResponse.data.panel_positions;
};

export const getEvents = () => {
    return axios.get(`${process.env.REACT_APP_BASE_URL}/api/events?populate=image,event_type,artist`, {
        headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
    });
};

export const getArtists = (page) => {
    return axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/artists?populate=image&pagination[page]=${page || 1}&pagination[pageSize]=100`,
        {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
        },
    );
};

export const getConcerts = () => {
    return axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/events?filters[event_type][name][$eq]=Concierto&populate=image,event_type,artist`,
        {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
        },
    );
};

export const getProjectById = (id) => {
    return axios.get(`${process.env.REACT_APP_BASE_URL}/api/project-crowdfundings/${id}?populate=*`);
};

export const getProfileData = async (key) => {
    const [url, role_name, token] = key;
    switch (role_name) {
        case "Artista/Banda": {
            const fullURL = `${url}?populate[artist][populate][0]=cover&populate=image`;

            const response = await axios.get(`${fullURL}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            const { artist: artistResponse, image: imageResponse } = response.data;
            const {
                biography,
                contact,
                facebook,
                instagram,
                name,
                spotify,
                template,
                twitter,
                cover: coverResponse,
            } = artistResponse;
            const cover = coverResponse?.url;
            const image = imageResponse?.url;
            const artistData = {
                biography,
                contact,
                facebook,
                instagram,
                name,
                spotify,
                template,
                twitter,
                cover,
                image,
            };
            return artistData;
        }

        case "Profesional": {
            const fullURL = `${url}?populate[artist][populate][0]=cover&populate=image`;

            const response = await axios.get(`${fullURL}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            const { artist: artistResponse, image: imageResponse } = response.data;
            const {
                biography,
                contact,
                facebook,
                instagram,
                name,
                spotify,
                template,
                twitter,
                cover: coverResponse,
            } = artistResponse;
            const cover = coverResponse?.url;
            const image = imageResponse?.url;
            const artistData = {
                biography,
                contact,
                facebook,
                instagram,
                name,
                spotify,
                template,
                twitter,
                cover,
                image,
            };
            return artistData;
        }
        default: {
            const fullURL = `${url}?populate[fanatic][populate][0]=portada&populate=image`;
            const response = await axios.get(`${fullURL}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            const { fanatic: fanaticResponse, image: imageResponse } = response.data;
            const {
                biography,
                contact,
                facebook,
                instagram,
                name,
                spotify,
                template,
                twitter,
                portada: coverResponse,
                interests,
            } = fanaticResponse;
            const cover = coverResponse?.url;
            const image = imageResponse?.url;
            const fanaticData = {
                biography,
                contact,
                facebook,
                instagram,
                name,
                spotify,
                template,
                twitter,
                cover,
                image,
                interests,
            };
            return fanaticData;
        }
    }
};

export const putProfileData = async (key, { arg }) => {
    const [, roleName, token] = key;

    if (arg.image) {
        if (!(arg.image instanceof File)) delete arg["image"];
    }
    if (arg.cover) {
        if (!(arg.cover instanceof File)) delete arg["cover"];
    }
    if (arg.poster) {
        if (!(arg.poster instanceof File)) delete arg["poster"];
    }

    switch (roleName) {
        case "Artista/Banda": {
            const requestOptions = {
                headers: { Authorization: `Bearer ${token}` },
            };
            const url = `${process.env.REACT_APP_BASE_URL}/api/artist/update/me`;
            const response = await axios.putForm(url, arg, requestOptions);
            const artist = response.data.data.artist;
            const metakeys = ["id", "type", "createdAt", "updatedAt", "publishedAt", "fans"];
            metakeys.forEach((metaKey) => {
                delete artist[metaKey];
            });
            artist.cover = artist?.cover?.url;
            artist.poster = artist?.poster?.url;
            artist.image = response.data.data.user?.image?.url;
            return artist;
        }

        case "Profesional": {
            const requestOptions = {
                headers: { Authorization: `Bearer ${token}` },
            };
            const url = `${process.env.REACT_APP_BASE_URL}/api/artist/update/me`;
            const response = await axios.putForm(url, arg, requestOptions);
            const artist = response.data.data.artist;
            const metakeys = ["id", "type", "createdAt", "updatedAt", "publishedAt", "fans"];
            metakeys.forEach((metaKey) => {
                delete artist[metaKey];
            });
            artist.cover = artist?.cover?.url;
            artist.image = response.data.data.user?.image?.url;
            return artist;
        }

        default: {

            const formData = new FormData();
            const keys = Object.keys(arg);
            keys.forEach((key) => {
                if (key !== "interests") formData.append(key, arg[key]);
                else if (arg.interests.length > 0) {
                    arg.interests.forEach((interest) => {
                        formData.append("interests", interest);
                    });
                } else formData.append("interests", []);
            });
            const requestOptions = {
                headers: { Authorization: `Bearer ${token}` },
            };

            const url = `${process.env.REACT_APP_BASE_URL}/api/fanatics/update/me`;
            const response = await axios.putForm(url, formData, requestOptions);

            const fanatic = response.data.data.fanatic;
            const metakeys = ["id", "type", "createdAt", "updatedAt", "publishedAt", "gallery"];
            metakeys.forEach((metaKey) => {
                delete fanatic[metaKey];
            });
            fanatic.cover = fanatic?.cover?.url;
            fanatic.image = response.data.data.user?.image?.url;
            return fanatic;
        }
    }
};

export const getArtistID = ([url, userID]) => {
    return axios.get(`${url}/${userID}?populate=artist`).then((response) => response.data.artist.id);
};

// export const getArtistDiaries = (key) => {
//     if (key) {
//         let [url, token] = key;
//         return axios
//             .get(`${url}`, {
//                 headers: {
//                     Authorization: `Bearer ${token}`,
//                 },
//             })
//             .then((response) => response.data.data);
//     } else return;
// };

export const getArtistDiaries = async (key) => {
    let [, token] = key;
    const url = `${process.env.REACT_APP_BASE_URL}/api/diary`;
    const response = await axios.get(`${url}/me`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    const metadataKeys = ["createdAt", "updatedAt", "publishedAt"];
    const diaries = response.data.data.diary.map((diary) => {
        metadataKeys.forEach((metaKey) => {
            delete diary[metaKey];
        });

        if (diary.image) {
            diary.image = diary.image.url;
        }

        return diary;
    });

    return diaries;
};

export const postArtistDiary = async (key, { arg }) => {
    const [apiURL, token] = key;
    const headers = { Authorization: `Bearer ${token}` };
    const response = await axios.postForm(`${apiURL}`, arg, {
        headers,
    });
    const metadataKeys = [
        "createdAt",
        "updatedAt",
        "publishedAt",
        "artist",
        "forum",
        "createdBy",
        "updatedBy",
    ];
    const diary = response.data.data.new;
    metadataKeys.forEach((metaKey) => delete diary[metaKey]);

    if (diary.image) {
        diary.image = diary.image.url;
    }

    return diary;
};

export const putArtistDiary = async (key, { arg }) => {
    const [, token] = key;
    const apiURL = `${process.env.REACT_APP_BASE_URL}/api/diary`;
    if (arg.image) {
        if (!(arg.image instanceof File)) delete arg["image"];
    }

    const { id: diaryId, ...diaryData } = arg;
    const headers = { Authorization: `Bearer ${token}` };
    const response = await axios.putForm(`${apiURL}/update/me?diary=${diaryId}`, diaryData, {
        headers,
    });
    const metadataKeys = [
        "createdAt",
        "updatedAt",
        "publishedAt",
        "artist",
        "forum",
        "createdBy",
        "updatedBy",
    ];
    const diary = response.data.data.new;
    metadataKeys.forEach((metaKey) => delete diary[metaKey]);

    if (diary.image) {
        diary.image = diary.image.url;
    }

    return diary;
};

export const deleteArtistDiary = (key, { arg }) => {
    let [url, token] = key;
    const headers = { Authorization: `Bearer ${token}` };
    return axios.delete(`${url}/${arg.id}`, {
        headers,
    });
};

export const getArtistVideos = async (key) => {
    let [url, token] = key;
    const response = await axios.get(`${url}/linked/me`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    const metadataKeys = ["createdAt", "updatedAt", "publishedAt", "input", "type"];
    const videos = response.data.data.videos.map((video) => {
        metadataKeys.forEach((metaKey) => {
            delete video[metaKey];
        });
        return video;
    });
    return videos;
};

export const postArtistVideos = async (key, { arg }) => {
    const [apiURL, token] = key;

    const headers = { "Content-Type": "application/json", Authorization: `Bearer ${token}` };
    const response = await axios.post(`${apiURL}/linked`, arg, {
        headers,
    });
    const { id, name, url, channel } = response.data.data.video;
    return { id, name, url, channel };
};

export const putArtistVideos = async (key, { arg }) => {
    const [apiURL, token] = key;
    const { id: videoId, ...video } = arg;

    const headers = { "Content-Type": "application/json", Authorization: `Bearer ${token}` };
    const response = await axios.put(`${apiURL}/linked/update/me?video=${videoId}`, video, {
        headers,
    });

    const { id, name, url, channel } = response.data.data.video;
    return { id, name, url, channel };
};

export const deleteArtistVideo = (key, { arg }) => {
    let [url, token] = key;
    const headers = { Authorization: `Bearer ${token}` };
    return axios.delete(`${url}s/${arg.id}`, {
        headers,
    });
};

export const getArtistAlbums = async (key) => {
    let [url, token] = key;
    const response = await axios.get(`${url}/me`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    const metadataKeys = ["createdAt", "updatedAt", "publishedAt"];
    const albums = response.data.data.albums.map((album) => {
        metadataKeys.forEach((metaKey) => {
            delete album[metaKey];
        });
        if (album.genre) {
            album.genreDescription = album.genre.name;
            album.genre = album.genre.id;
        }
        if (album.image) {
            album.image = album.image.url;
        }

        return album;
    });

    return albums;
};

export const postArtistAlbum = async (key, { arg }) => {
    const [apiURL, token] = key;
    const headers = { Authorization: `Bearer ${token}` };
    const response = await axios.postForm(`${apiURL}s`, arg, {
        headers,
    });
    const metadataKeys = ["createdAt", "updatedAt", "publishedAt"];
    const album = response.data.data.album;
    metadataKeys.forEach((metaKey) => delete album[metaKey]);
    if (album.genre) {
        album.genreDescription = album.genre.name;
        album.genre = album.genre.id;
    }
    if (album.image) {
        album.image = album.image.url;
    }

    return album;
};

export const putArtistAlbum = async (key, { arg }) => {
    const [apiURL, token] = key;
    if (arg.image) {
        if (!(arg.image instanceof File)) delete arg["image"];
    }
    delete arg["audio"];
    delete arg["genreDescription"];
    const { id: albumId, genreDescription, ...albumData } = arg;
    const headers = { Authorization: `Bearer ${token}` };
    const response = await axios.putForm(`${apiURL}/update/me?album=${albumId}`, albumData, {
        headers,
    });
    const metadataKeys = ["createdAt", "updatedAt", "publishedAt"];
    const album = response.data.data.album;
    metadataKeys.forEach((metaKey) => delete album[metaKey]);
    if (album.genre) {
        album.genreDescription = album.genre.name;
        album.genre = album.genre.id;
    }
    if (album.image) {
        album.image = album.image.url;
    }

    return album;
};

export const deleteArtistAlbum = (key, { arg }) => {
    let [url, token] = key;
    const headers = { Authorization: `Bearer ${token}` };
    return axios.delete(`${url}s/${arg.id}`, {
        headers,
    });
};

export const getGenres = async (key) => {
    const response = await axios.get(key);

    const genres = response.data.data;
    const genresData = genres.map((genre) => ({ id: genre.id, name: genre.attributes.name }));
    return genresData;
};

export const getArtistEvents = async (key) => {
    let [url, token] = key;
    const response = await axios.get(`${url}s/me`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    const metadataKeys = ["createdAt", "updatedAt", "publishedAt", "input", "artist"];
    const events = response.data.data.events.map((event) => {
        metadataKeys.forEach((metaKey) => {
            delete event[metaKey];
        });
        if (event.event_type) {
            event.typeDescription = event.event_type.name;
            event.event_type = event.event_type.id;
        }
        if (event.image) event.image = event.image.url;

        return event;
    });
    return events;
};

export const deleteArtistEvent = async (key, { arg }) => {
    let [url, token] = key;
    const headers = { Authorization: `Bearer ${token}` };
    const response = await axios.put(
        `${url}/cancel?event=${arg.id}`,
        {},
        {
            headers,
        },
    );
    const event = response.data.data.event;
    const metadataKeys = ["createdAt", "updatedAt", "publishedAt", "artist"];
    metadataKeys.forEach((metaKey) => delete event[metaKey]);
    if (event.event_type) {
        event.typeDescription = event.event_type.name;
        event.event_type = event.event_type.id;
    }
    if (event.image) event.image = event.image.url;
    return event;
};

export const postArtistEvents = async (key, { arg }) => {
    const [apiURL, token] = key;
    arg.amount = arg.ticketamount;
    delete arg["ticketamount"];

    const headers = { Authorization: `Bearer ${token}` };
    const response = await axios.postForm(`${apiURL}s`, arg, {
        headers,
    });

    const event = response.data.data.event;
    const metadataKeys = ["createdAt", "updatedAt", "publishedAt", "artist"];
    metadataKeys.forEach((metaKey) => delete event[metaKey]);
    if (event.event_type) {
        event.typeDescription = event.event_type.name;
        event.event_type = event.event_type.id;
    }

    if (event.image) {
        event.image = event.image.url;
    }
    return event;
};

export const putArtistEvents = async (key, { arg }) => {
    const [apiURL, token] = key;
    arg.amount = arg.ticketamount;
    delete arg["ticketamount"];
    const { id: eventId, ...eventData } = arg;
    const metadataKeys = ["createdAt", "updatedAt", "publishedAt", "input", "artist"];
    const headers = { "Content-Type": "application/json", Authorization: `Bearer ${token}` };
    const response = await axios.putForm(`${apiURL}/update/me?event=${eventId}`, eventData, {
        headers,
    });

    const event = response.data.data.event;
    metadataKeys.forEach((metaKey) => delete event[metaKey]);

    if (event.event_type) {
        event.typeDescription = event.event_type.name;
        event.event_type = event.event_type.id;
    }
    if (event.image) event.image = event.image.url;

    return event;
};

export const getEventTypes = async (key) => {
    const response = await axios.get(key);
    const eventTypes = response.data.data;
    const eventTypesData = eventTypes.map((eventType) => ({
        id: eventType.id,
        name: eventType.attributes.name,
    }));
    return eventTypesData;
};

export const getArtistProducts = async (key) => {
    let [url, token] = key;
    const response = await axios.get(`${url}/me`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    const metadataKeys = ["createdAt", "updatedAt", "publishedAt", "sales", "artist", "valoration"];
    const products = response.data.data.products;
    metadataKeys.forEach((metaKey) => {
        products.forEach((product) => delete product[metaKey]);
    });
    products.forEach((product) => {
        product.image = product.image ? product.image[0].url : null;
        product.content = product.content ? product.content[0].url : null;
        product.category = product.categories ? product.categories[0].id : null;
        delete product["categories"];
    });
    return products;
};

export const postArtistProducts = async (key, { arg }) => {
    const [apiURL, token] = key;
    const headers = { Authorization: `Bearer ${token}` };
    const response = await axios.postForm(`${apiURL}s`, arg, {
        headers,
    });

    const product = response.data.data.product;
    const metadataKeys = ["createdAt", "updatedAt", "publishedAt"];
    metadataKeys.forEach((metaKey) => delete product[metaKey]);
    product.image = product?.image?.[0]?.url;
    return product;
};

export const putArtistProducts = async (key, { arg }) => {
    const [apiURL, token] = key;
    const { id: productId, ...product } = arg;

    const headers = { "Content-Type": "application/json", Authorization: `Bearer ${token}` };
    const response = await axios.putForm(`${apiURL}/update/me?product=${productId}`, product, {
        headers,
    });

    const { id, name, price, amount, description, type } = response.data.data.new;
    const image = response.data.data.new.image ? response.data.data.new.image[0].url : null;
    return { id, name, price, amount, description, type, image };
};

export const getProductsCategories = async (key) => {
    const response = await axios.get(key);
    const categoryTypes = response.data.data;
    const categoryTypesData = categoryTypes.map((categoryType) => ({
        id: categoryType.id,
        name: categoryType.attributes.name,
    }));
    return categoryTypesData;
};

export const deleteArtistProduct = (key, { arg }) => {
    let [url, token] = key;
    const headers = { Authorization: `Bearer ${token}` };
    return axios.delete(`${url}s/${arg.id}`, {
        headers,
    });
};

export const getGroupChat = async (key) => {
    let [url, token] = key;
    if (!url || !token) {
        return;
    }
    const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/${url}`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });

    return response;
};

export const getArtistProjects = async (key) => {
    let [url, token] = key;
    const response = await axios.get(`${url}/me`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    const metadataKeys = ["createdAt", "updatedAt", "publishedAt"];
    const projects = response.data.data.projects.map((project) => {
        metadataKeys.forEach((metaKey) => delete project[metaKey]);
        project.image = project.image ? project.image.url : null;
        project.state = project.project_state ? project.project_state.id : null;
        project.type = project.project_type ? project.project_type.id : null;

        return project;
    });

    return projects;
};

export const postArtistProjects = async (key, { arg }) => {
    const [apiURL, token] = key;
    const headers = { Authorization: `Bearer ${token}` };
    arg.initial_date = dayjs(arg.initial_date).format("YYYY-MM-DD");
    arg.final_date = dayjs(arg.final_date).format("YYYY-MM-DD");
    delete arg["state"];
    const response = await axios.postForm(`${apiURL}s`, arg, {
        headers,
    });

    const project = response.data.data.project;
    const metadataKeys = ["createdAt", "updatedAt", "publishedAt"];
    metadataKeys.forEach((metaKey) => delete project[metaKey]);
    project.image = project?.image?.url;
    project.state = project?.project_state?.id;
    project.type = project?.project_type?.id;
    return project;
};

export const putArtistProjects = async (key, { arg }) => {
    const [apiURL, token] = key;
    const { id: projectId, ...project } = arg;

    const headers = { "Content-Type": "application/json", Authorization: `Bearer ${token}` };
    const response = await axios.putForm(`${apiURL}/update/me?project=${projectId}`, project, {
        headers,
    });
    const metadataKeys = ["createdAt", "updatedAt", "publishedAt", "artist", "project_collaborators"];

    const projectResponse = response.data.data.new;
    metadataKeys.forEach((metaKey) => delete projectResponse[metaKey]);
    projectResponse.image = projectResponse.image ? projectResponse.image.url : null;
    projectResponse.state = projectResponse.project_state ? projectResponse.project_state.id : null;
    projectResponse.type = projectResponse.project_type ? projectResponse.project_type.id : null;
    return projectResponse;
};

export const deleteArtistProject = (key, { arg }) => {
    let [url, token] = key;
    const headers = { Authorization: `Bearer ${token}` };
    return axios.delete(`${url}s/${arg.id}`, {
        headers,
    });
};

export const getArtistClasses = async (key) => {
    let [url, token] = key;
    const response = await axios.get(`${url}/me`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });

    const metadataKeys = ["createdAt", "updatedAt", "publishedAt"];
    const classes = response.data.data.clases.map((classEl) => {
        metadataKeys.forEach((metaKey) => delete classEl[metaKey]);
        classEl.image = classEl.image ? classEl.image.url : null;
        classEl.section = classEl.section ? classEl.section.id : null;
        return classEl;
    });

    return classes;
};

export const getClassesSections = async (key) => {
    const [url, userToken, language] = key;
    const headers = { Authorization: `Bearer ${userToken}` };
    const response = await axios.get(url, { headers });
    const sectionTypes = response.data.data;
    const sectionTypesData = sectionTypes.map((sectionType) => ({
        id: sectionType.id,
        name: sectionType.attributes.name,
    }));
    return sectionTypesData;
};

export const postArtistClasses = async (key, { arg }) => {
    const [apiURL, token] = key;
    if (arg.resources) {
        const firstResource = Array.from(arg.resources)[0];
        arg.resources = firstResource;
    }
    const headers = { Authorization: `Bearer ${token}` };
    const response = await axios.postForm(`${apiURL}`, arg, {
        headers,
    });

    const classEl = response.data.data.clase;
    const metadataKeys = ["createdAt", "updatedAt", "publishedAt"];
    metadataKeys.forEach((metaKey) => delete classEl[metaKey]);
    classEl.image = classEl?.image?.url;
    return classEl;
};

export const putArtistClasses = async (key, { arg }) => {
    const [apiURL, token] = key;
    const { id: classId, ...classEl } = arg;

    const headers = { "Content-Type": "application/json", Authorization: `Bearer ${token}` };
    const response = await axios.putForm(`${apiURL}/update/me?product=${classId}`, classEl, {
        headers,
    });

    const { id, name, price, amount, description, type } = response.data.data.new;
    const image = response.data.data.new.image ? response.data.data.new.image[0].url : null;
    return { id, name, price, amount, description, type, image };
};

export const getArtistProfileData = async (key) => {
    const url = key;

    const fullURL = `${url}?populate[artist][populate][0]=cover,poster&populate=image`;

    const response = await axios.get(`${fullURL}`);

    const { artist: artistResponse, image: imageResponse } = response.data;

    const {
        biography,
        declaration,
        contact,
        facebook,
        instagram,
        name,
        spotify,
        template,
        twitter,
        cover: coverResponse,
        poster: posterResponse,
    } = artistResponse;
    const cover = coverResponse?.url;
    const image = imageResponse?.url;
    const poster = posterResponse?.url;
    const artistData = {
        biography,
        declaration,
        contact,
        facebook,
        instagram,
        name,
        spotify,
        template,
        twitter,
        cover,
        image,
        poster,
    };
    return artistData;
};

export const getArtistGallery = async (key) => {
    let [url, token] = key;
    const response = await axios.get(`${url}/me`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });

    const galleryresponse = response.data.data.galleries;

    if (galleryresponse) {
        const gallery = response.data.data.galleries.map((galleryData) => {
            const { id, image, description } = galleryData;
            return { id, image, description };
        });
        return gallery;
    }

    return [];
};

export const postArtistGallery = async (key, { arg }) => {
    const [, token] = key;
    const apiURL = `${process.env.REACT_APP_BASE_URL}/api/galleries`;

    const headers = { Authorization: `Bearer ${token}` };
    const response = await axios.postForm(`${apiURL}`, arg, {
        headers,
        formSerializer: { indexes: null },
    });

    return response.data.data;
};

export const putArtistGallery = async (key, { arg }) => {
    const [, token] = key;
    const apiURL = `${process.env.REACT_APP_BASE_URL}/api/artist/update/me`;

    const headers = { Authorization: `Bearer ${token}` };
    const response = await axios.putForm(`${apiURL}`, arg, {
        headers,
        formSerializer: { indexes: null },
    });

    return response.data.data;
};

export const putImageArtistGallery = async (key, { arg }) => {
    const [url, token] = key;
    const { id, image } = arg;
    const apiURL = `${url}/update/${id}`;
    const headers = { Authorization: `Bearer ${token}` };
    const response = await axios.putForm(
        `${apiURL}`,
        { image: image },
        {
            headers,
            formSerializer: { indexes: null },
        },
    );
    return response.data.data;
};

export const deleteArtistGallery = (key, { arg }) => {
    let [, token] = key;
    const apiURL = `${process.env.REACT_APP_BASE_URL}/api/galleries`;
    const headers = { Authorization: `Bearer ${token}` };
    return axios.delete(`${apiURL}/${arg.id}`, {
        headers,
    });
};

export const getArtistAudios = async (key) => {
    let [url, token] = key;
    const response = await axios.get(url + "/me", {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    const musicResponse = response.data.data.audios;
    if (musicResponse) {
        const musics = musicResponse.map((music) => {
            const { id, name, author, duration, image, album } = music;
            const imageUrl = image?.url || "";
            const albumId = album ? album.id : "";
            const albumName = album ? album.name : "";
            return { id, name, author, duration, image: imageUrl, album: albumId, albumName };
        });
        return musics;
    }
    return [];
};

export const postArtistAudios = async (key, { arg }) => {
    const [, token] = key;
    const apiURL = `${process.env.REACT_APP_BASE_URL}/api/audios`;
    const headers = { Authorization: `Bearer ${token}` };
    arg.single = "album";
    const response = await axios.postForm(`${apiURL}`, arg, {
        headers,
    });

    return response.data;
};

export const putArtistAudios = async (key, { arg }) => {
    const [url, token] = key;
    const { id } = arg;
    if (arg.image) {
        if (!(arg.image instanceof File)) delete arg["image"];
    }
    delete arg["albumName"];
    const apiURL = `${url + "/update/" + id}`;
    const headers = { Authorization: `Bearer ${token}` };

    const response = await axios.putForm(`${apiURL}`, arg, {
        headers,
    });

    return response.data.data;
};

export const getArtistAudiosForTemplate = async (key) => {
    let [url, artistId] = key;
    const audioResponse = await axios.get(`${url + "/" + artistId}`);
    const audios = audioResponse.data.data.audio;
    return audios;
};

export const getArtistAlbumsForTemplate = async (key) => {
    let [url, artistId] = key;
    const albumResponse = await axios.get(`${url + "/" + artistId}`);
    const albums = albumResponse.data.data.albums;
    return albums;
};

export const getArtistEventsForTemplate = async (key) => {
    let [url, artistId] = key;
    const eventResponse = await axios.get(`${url + "/" + artistId}`);
    const events = eventResponse.data.data.events;
    return events;
};

export const getArtistProductsForTemplate = async (key) => {
    let [url, artistId] = key;
    const productResponse = await axios.get(`${url + "/" + artistId}`);
    const products = productResponse.data.data.products;
    if (products) {
        const productsData = products.map((product) => {
            product.image = product.image[0] ? product.image[0].url : "";
            return product;
        });
        return productsData;
    }
    return [];
};

export const getArtistVideosForTemplate = async (key) => {
    let [url, artistId] = key;
    const videoResponse = await axios.get(`${url + "/" + artistId}`);
    const videos = videoResponse.data.data.videos;
    if (videos) {
        return videos;
    }
    return [];
};

export const getArtistDiariesForTemplate = async (key) => {
    const baseUrl = `${process.env.REACT_APP_BASE_URL}`;
    let [url, artistId] = key;
    const diaryResponse = await axios.get(`${url + "/" + artistId}`);
    const diaries = diaryResponse.data.data.diary;
    if (diaries) {
        const diariesData = diaries.map((diary) => {
            diary.image = diary.image ? baseUrl + diary.image.url : "";
            return diary;
        });
        return diariesData;
    }
    return [];
};

export const getArtistGalleryForTemplate = async (key) => {
    const baseUrl = `${process.env.REACT_APP_BASE_URL}`;
    let [url, artistId] = key;
    const galleryResponse = await axios.get(`${url + "/" + artistId}`);
    const galleries = galleryResponse.data.data.gallery;
    if (galleries) {
        const galleriesData = galleries.map((gallery) => {
            gallery.image.map((image) => {
                image.url = baseUrl + image?.url;
                return image;
            });
            return gallery;
        });
        return galleriesData;
    }
    return [];
};

export const getAllNews = async (key) => {
    const [url, paginationModel, newsFilter] = key;
    const paginationQueryParams = `pagination[pageSize]=${paginationModel.pageSize}&pagination[page]=${paginationModel.page + 1}&populate=news_adder_types,tags,rss,scrape`;
    const endpointUrl = `${process.env.REACT_APP_BASE_URL}/api${url}?filters[state]=${newsFilter}&sort=updatedAt:desc&${paginationQueryParams}`;
    const newsResponse = await axios.get(endpointUrl);
    return { news: newsResponse.data.data, pagination: newsResponse.data.pagination };
};

export const getMoreNews = async (key) => {
    const [url, paginationModel] = key;
    const paginationQueryParams = `pagination[pageSize]=${paginationModel.pageSize}&pagination[page]=${paginationModel.page + 1}&populate=news_adder_types,tags,rss,scrape`;
    const endpointUrl = `${process.env.REACT_APP_BASE_URL}/api${url}?${paginationQueryParams}`;
    const newsResponse = await axios.get(endpointUrl);
    return { news: newsResponse.data.data, pagination: newsResponse.data.pagination };
};

export const getFilteredNews = async (key) => {
    const [url, paginationModel, filterAttribute, newsFilter] = key;
    const paginationQueryParams = `pagination[pageSize]=${paginationModel.pageSize}&pagination[page]=${paginationModel.page + 1}`;
    const endpointUrl = `${process.env.REACT_APP_BASE_URL}/api${url}?filters[state]=aprobada&${filterAttribute}=${newsFilter}&sort=updatedAt:desc&${paginationQueryParams}`;
    const newsResponse = await axios.get(endpointUrl);
    return { news: newsResponse.data.data, pagination: newsResponse.data.pagination };
};
export const getFilteredCrowdFundings = async (key) => {
    const [url, paginationModel, filterAttribute, crowdFundingsFilter] = key;
    const paginationQueryParams = `pagination[pageSize]=${paginationModel.pageSize}&pagination[page]=${paginationModel.page + 1}`;
    const endpointUrl = `${process.env.REACT_APP_BASE_URL}/api${url}?${filterAttribute}=${crowdFundingsFilter}&sort=publishedAt:desc&${paginationQueryParams}&populate=image`;
    const projectsResponse = await axios.get(endpointUrl);
    return { projects: projectsResponse.data.results, pagination: projectsResponse.data.pagination };
};
export const getFilteredDebates = async (key) => {
    const [url, paginationModel, filterAttribute, debatesFilter] = key;
    const paginationQueryParams = `pagination[pageSize]=${paginationModel.pageSize}&pagination[page]=${paginationModel.page + 1}`;
    const endpointUrl = `${process.env.REACT_APP_BASE_URL}/api${url}?${filterAttribute}=${debatesFilter}&sort=publishedAt:desc&${paginationQueryParams}&populate=image,news_adder`;
    const debatesResponse = await axios.get(endpointUrl);

    return { debates: debatesResponse.data.data, pagination: debatesResponse.data.pagination };
};

export const getFilteredTeleNews = async (key) => {
    const [url, paginationModel, filterAttribute, teleNewsFilter] = key;
    const paginationQueryParams = `pagination[pageSize]=${paginationModel.pageSize}&pagination[page]=${paginationModel.page + 1}`;
    const endpointUrl = `${process.env.REACT_APP_BASE_URL}/api${url}?filters[state]=aprobado&${filterAttribute}=${teleNewsFilter}&sort=publishedAt:desc&${paginationQueryParams}`;
    const teleNewsResponse = await axios.get(endpointUrl);

    return { teleNews: teleNewsResponse.data.data, pagination: teleNewsResponse.data.meta.pagination };
};

export const putNewsState = async (key, { arg }) => {
    const [url] = key;
    const endpointUrl = `${process.env.REACT_APP_BASE_URL}/api${url}/update-state`;
    const { authToken, ...newsData } = arg;
    const { newsToSubmit: ids, newState: state } = newsData;
    const newsPutData = { ids, state };

    const newsResponse = await axios.put(endpointUrl, newsPutData, {
        headers: {
            Authorization: `Bearer ${authToken}`,
        },
    });
    return newsResponse;
};

export const getEditorialItemData = async (key) => {
    const [, slug, itemId, queryString, userToken] = key;
    const endpointUrl = `${process.env.REACT_APP_BASE_URL}/api/${slug}/${itemId}${queryString}`;
    const axiosResponse = await axios.get(endpointUrl, {
        headers: {
            Authorization: `Bearer ${userToken}`,
        },
    });
    return axiosResponse.data?.data || axiosResponse.data;
};

export const getNewsApproved = async (key) => {
    const [url, userToken, page] = key;

    const endpointUrl = `${process.env.REACT_APP_BASE_URL}/api${url}?filters[state]=aprobada&sort=updatedAt:desc&pagination[pageSize]=100&pagination[page]=${page || 1}&populate=news_adder_types,tags`;
    const newsResponse = await axios.get(endpointUrl, {
        headers: {
            //Authorization: `Bearer ${userToken}`,
        },
    });
    return { data: newsResponse?.data?.data, pagination: newsResponse?.data?.pagination };
};

export const getAllTeleNews = async (key) => {
    const [url, paginationModel] = key;
    const paginationQueryParams = `pagination[pageSize]=${paginationModel.pageSize}&pagination[page]=${paginationModel.page}&sort=updatedAt:desc`;
    const endpointUrl = `${process.env.REACT_APP_BASE_URL}/api/${url}&${paginationQueryParams}`;

    const newsResponse = await axios.get(endpointUrl, {
        headers: {},
    });
    return { data: newsResponse?.data?.data, pagination: newsResponse?.data?.meta?.pagination };
};

export const getAllLastNewsApproved = async (key) => {
    const [url, paginationModel] = key;
    const paginationQueryParams = `pagination[pageSize]=${paginationModel.pageSize}&pagination[page]=${paginationModel.page}&sort=updatedAt:desc`;
    const endpointUrl = `${process.env.REACT_APP_BASE_URL}/api${url}&${paginationQueryParams}&populate=*`;
    const newsResponse = await axios.get(endpointUrl, {
        headers: {},
    });
    return { data: newsResponse?.data?.data, pagination: newsResponse?.data?.pagination };
};

export const getAllShowbiz = async (key) => {
    const [url, paginationModel] = key;
    const paginationQueryParams = `pagination[pageSize]=${paginationModel.pageSize}&pagination[page]=${paginationModel.page}`;
    const endpointUrl = `${process.env.REACT_APP_BASE_URL}/api/${url}&${paginationQueryParams}&populate=*`;
    const newsResponse = await axios.get(endpointUrl, {
        headers: {},
    });
    return { data: newsResponse?.data?.data, pagination: newsResponse?.data?.pagination };
};

export const getAllStories = async (key) => {
    const [url, paginationModel] = key;
    const paginationQueryParams = `pagination[pageSize]=${paginationModel.pageSize}&pagination[page]=${paginationModel.page}`;

    const endpointUrl = `${process.env.REACT_APP_BASE_URL}/api/${url}&${paginationQueryParams}&sort=updatedAt:desc&populate=*`;

    const newsResponse = await axios.get(endpointUrl, {
        headers: {},
    });
    return { data: newsResponse?.data?.data, pagination: newsResponse?.data?.pagination };
};

export const getAllRecordings = async (key) => {
    const [url, paginationModel] = key;
    const paginationQueryParams = `pagination[pageSize]=${paginationModel.pageSize}&pagination[page]=${paginationModel.page}`;

    const endpointUrl = `${process.env.REACT_APP_BASE_URL}/api/${url}&${paginationQueryParams}&sort=updatedAt:desc&populate=*`;

    const newsResponse = await axios.get(endpointUrl, {
        headers: {},
    });
    return { data: newsResponse?.data?.data, pagination: newsResponse?.data?.pagination };
};

export const getAllEventNews = async (key) => {
    const [url, paginationModel] = key;
    const paginationQueryParams = `pagination[pageSize]=${paginationModel.pageSize}&pagination[page]=${paginationModel.page}`;

    const endpointUrl = `${process.env.REACT_APP_BASE_URL}/api/${url}&${paginationQueryParams}&sort=updatedAt:desc&populate=*`;

    const newsResponse = await axios.get(endpointUrl, {
        headers: {},
    });
    return { data: newsResponse?.data?.data, pagination: newsResponse?.data?.pagination };
};

export const getAllDebates = async (key) => {
    const [url, paginationModel, authToken] = key;
    const paginationQueryParams = `pagination[pageSize]=${paginationModel.pageSize}&pagination[page]=${paginationModel.page}`;
    const endpointUrl = `${process.env.REACT_APP_BASE_URL}/api${url}?${paginationQueryParams}&sort=publishedAt:desc&d=si`;
    const newsResponse = await axios.get(endpointUrl, {
        headers: {
            Authorization: `Bearer ${authToken}`,
        },
    });
    return { data: newsResponse?.data?.data, pagination: newsResponse?.data?.pagination };
};

export const getAllLatinMusic = async (key) => {
    const [url, paginationModel] = key;
    const paginationQueryParams = `pageSize=${paginationModel.pageSize}&page=${paginationModel.page}`;
    const endpointUrl = `${process.env.REACT_APP_BASE_URL}/api${url}&${paginationQueryParams}`;
    const newsResponse = await axios.get(endpointUrl, {
        headers: {},
    });
    return { data: newsResponse?.data?.data, pagination: newsResponse?.data?.pagination };
};

export const getTVPlaylistToday = async (key) => {
    const [url] = key;
    const currentDay = new Date();
    const currentDayFormatted = getFormatDate(currentDay);
    const endpointUrl = `${process.env.REACT_APP_BASE_URL}/api${url}?filters[date]=${currentDayFormatted}`;
    const res = await axios.get(endpointUrl);
    return { data: res?.data?.results[0] };
};

export const getChannelById = async (id) => {
    const url = `${process.env.REACT_APP_BASE_URL}/api/tv-canals/${id}`;
    const response = await axios.get(url);
    return response;
};

export const getAllVideos = async (key) => {
    const [url, paginationModel, newsFilter, authToken] = key;
    const paginationQueryParams = `pagination[pageSize]=${paginationModel.pageSize}&pagination[page]=${paginationModel.page + 1}`;
    const endpointUrl = `${process.env.REACT_APP_BASE_URL}/api/${url}?sort[0]=updatedAt:desc&filters[state]=${newsFilter}&${paginationQueryParams}`;
    const newsResponse = await axios.get(endpointUrl, {
        headers: {
            Authorization: `Bearer ${authToken}`,
        },
    });
    return { data: newsResponse?.data?.data, pagination: newsResponse?.data?.meta?.pagination };
};

export const getVideosApproved = async (key) => {
    const [url, authToken] = key;

    const endpointUrl = `${process.env.REACT_APP_BASE_URL}/api${url}?filters[state]=aprobado&filters[playlist]=true`;
    const newsResponse = await axios.get(endpointUrl, {
        headers: {
            //Authorization: `Bearer ${authToken}`,
        },
    });
    return { data: newsResponse?.data?.data, pagination: newsResponse?.data?.meta?.pagination };
};
export const putNewsStateVideo = async (key, { arg }) => {
    const [url] = key;
    const endpointUrl = `${process.env.REACT_APP_BASE_URL}/api${url}/update-state`;
    const { authToken, ...newsData } = arg;
    const { newsToSubmit: data, newState: state } = newsData;
    const newsPutData = { data, state };

    const newsResponse = await axios.put(endpointUrl, newsPutData, {
        headers: {
            Authorization: `Bearer ${authToken}`,
        },
    });
    return newsResponse;
};

export const putVideoPlaylist = async (key, { arg }) => {
    const [url] = key;
    const endpointUrl = `${process.env.REACT_APP_BASE_URL}/api${url}/update-playlist`;
    const { authToken, ...newsData } = arg;
    const { id: data, playlist } = newsData;
    const newsPutData = { data, playlist };

    const newsResponse = await axios.put(endpointUrl, newsPutData, {
        headers: {
            Authorization: `Bearer ${authToken}`,
        },
    });
    return newsResponse;
};

export const getPanelPositionEntities = async (key) => {
    const [, panelPositionId, userToken] = key;

    const axiosResponse = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/panel-positions/${panelPositionId}?populate[panel_entities]=true`,
        {
            headers: {
                Authorization: `Bearer ${userToken}`,
            },
        },
    );

    const entities = axiosResponse.data.data.attributes.panel_entities.data;
    const flatEntities = entities.map((entity) => ({ id: entity.id, name: entity.attributes.name }));
    return flatEntities;
};

export const getActiveSections = async () => {
    const axiosResponse = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/panel-sections?filters[active]=true&sort=order:asc`,
    );
    return axiosResponse.data.data;
};

export const getAllSongs = async (key) => {
    const [url, paginationModel, authToken] = key;
    const paginationQueryParams = `pagination[pageSize]=${paginationModel.pageSize}&pagination[page]=${paginationModel.page + 1}`;
    const endpointUrl = `${process.env.REACT_APP_BASE_URL}/api/${url}?populate=*?&${paginationQueryParams}`;
    const songsResponse = await axios.get(endpointUrl, {
        headers: {
            Authorization: `Bearer ${authToken}`,
        },
    });
    return { data: songsResponse?.data?.data, pagination: songsResponse?.data?.meta?.pagination };
};

export const createNews = async (key) => {
    const { title, content, link, details, image } = key;
    const token = sessionStorage.getItem("token");

    const formdata = new FormData();
    formdata.append("type", "interna");
    formdata.append("title", title);
    formdata.append("content", content);
    formdata.append("link", link);
    formdata.append("details", details);
    formdata.append("local_image", image);

    const config = {
        method: "post",
        url: `${process.env.REACT_APP_BASE_URL}/api/news-adders`,
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
        },
        data: formdata,
    };
    const response = await axios(config);
    console.log({ formdata });
    console.log({ response });
    return response;
};

export const getAllNewsCreated = async () => {
    const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/news-adders?filters[type]=interna&populate=*`,
    );
    return response.data;
};

export const getNewsCreatedId = async (key) => {
    const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/news-adders/${key}?populate=*`,
    );
    if (response.status !== 200) {
        return false;
    }
    if (response.data.data.attributes.details !== null && response.data.data.attributes.details) {
        return response.data;
    }
    return false;
};

export const getNewsPerId = async (key) => {
    const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/news-adders/${key}?populate=*`,
    );
    if (response.status !== 200) {
        return false;
    }
    return response.data;
};

export const deleteNewsCreated = async (key) => {
    const token = sessionStorage.getItem("token");
    const response = await axios(`${process.env.REACT_APP_BASE_URL}/api/news-adders/${key}`, {
        method: "delete",
        headers: { Authorization: `Bearer ${token}` },
    });
    if (response.status !== 200) {
        return false;
    }
    return response;
};

export const editNewsCreated = async (id, key) => {
    const { title, content, details, image } = key;
    const token = sessionStorage.getItem("token");

    const formdata = new FormData();
    formdata.append("title", title);
    formdata.append("content", content);
    formdata.append("details", details);
    if (image || image !== null) {
        formdata.append("local_image", image);
    }

    const config = {
        method: "post",
        url: `${process.env.REACT_APP_BASE_URL}/api/news-adders/${id}`,
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
        },
        data: formdata,
    };
    const response = await axios(config);
    return response;
};

export const editNewsExt = async (id, key) => {
    const { title, details } = key;
    const token = sessionStorage.getItem("token");
    const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}/api/news-adders/${id}`,
        { data: key },
        {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        },
    );
    console.log(response);
    if (response.status !== 200) {
        return false;
    }
    return response;
};

export const editNewsHybrid = async (id, key) => {
    const { title, content, image, extra_link } = key;
    const token = sessionStorage.getItem("token");

    const formdata = new FormData();
    formdata.append("title", title);
    formdata.append("content", content);
    formdata.append("image", image);
    formdata.append("extra_link", extra_link);

    const config = {
        method: "post",
        url: `${process.env.REACT_APP_BASE_URL}/api/news-adders/${id}`,
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
        },
        data: formdata,
    };
    const response = await axios(config);
    return response;
};

export const getTagsAutocomplete = (name) => {
    return axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/tags/select?filters[name][$contains]=${name}`,
        {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
        },
    );
};

export const getTrendNews = (id, page) => {
    return axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/news-adders/trend/${id}?pagination[page]=${page}&pagination[pageSize]=10&populate=*`,
    );
};

export const getHybridNews = async () => {
    const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/news-adders?filters[type]=hibrida&populate=*`,
    );
    return response.data;
};

export const createHybridNews = async (key) => {
    const token = sessionStorage.getItem("token");
    const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/news-adders`,
        { type: "interna", ...key },
        {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        },
    );
    console.log(response);
    if (response.status !== 200) {
        return false;
    }
    return response;
};

export const getFilteredTrendingNews = async (key) => {
    const [url, paginationModel, filterAttribute, newsFilter, authToken] = key;
    const paginationQueryParams = `pagination[pageSize]=${paginationModel.pageSize}&pagination[page]=${paginationModel.page + 1}`;
    const endpointUrl = `${process.env.REACT_APP_BASE_URL}/api${url}`;
    const newsResponse = await axios.get(endpointUrl, {
        headers: {
            Authorization: `Bearer ${authToken}`,
        },
    });
    return { news: newsResponse.data?.results, pagination: newsResponse.data.pagination };
};

export const removeHtmlTags = (text) => {
    if (!text) {
        return text;
    }
    const tags = [
        "p",
        "h1",
        "h2",
        "h3",
        "h4",
        "h5",
        "h6",
        "img",
        "figure",
        "noscript",
        "label",
        "div",
        "script",
        "ins",
        "br",
        "a",
        "strong",
    ];
    const pattern = new RegExp(`</?(${tags.join("|")})[^>]*>`, "gi");
    const cleanedText = text.replace(pattern, "");
    return cleanedText;
};

export const getFilteredMainNews = async (key) => {
    const [url, paginationModel, filterAttribute, newsFilter] = key;
    const paginationQueryParams = `pagination[pageSize]=${paginationModel.pageSize}&pagination[page]=${paginationModel.page + 1}`;
    const currentDay = new Date();
    currentDay.setHours(0);
    currentDay.setMinutes(0);
    currentDay.setSeconds(0);
    currentDay.setDate(currentDay.getDate() - 3);
    const currentDayOnIso = currentDay.toISOString();
    const endpointUrl = `${process.env.REACT_APP_BASE_URL}/api${url}?filters[state]=aprobada&filters[createdAt][$gte]=${currentDayOnIso}&${filterAttribute}=${newsFilter}&sort=updatedAt:desc&${paginationQueryParams}`;
    const newsResponse = await axios.get(endpointUrl);
    return { news: newsResponse.data.data, pagination: newsResponse.data.pagination };
};
export const editNews = (id, obj) => {
    return axios.put(`${process.env.REACT_APP_BASE_URL}/api/news-adders/${id}`, obj, {
        headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
    });
};
export const getSearchResult = async (key, pageSize, page) => {
    const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/panel-card/allsearcher?word=${key}&pagination[pageSize]=${pageSize}&pagination[page]=${page}&populate=*`,
    );
    return response;
};

export const postCreateExtNewsByUrl = async (key) => {
    const { link } = key;
    const token = sessionStorage.getItem("token");

    const formdata = new FormData();
    formdata.append("url", link);

    const config = {
        method: "post",
        url: `${process.env.REACT_APP_BASE_URL}/api/news-adder/createbyurl`,
        headers: {
            Authorization: `Bearer ${token}`,
        },
        data: formdata,
    };
    const response = await axios(config);
    console.log({ response });
    return response;
};

export const getVideosLatinMusic = async (filter, pagination) => {
    const searchParams = new URLSearchParams();
    if (filter) {
        Object.entries(filter).forEach(([key, value]) => {
            if (!value || value === "false") return;
            searchParams.append(key, value);
        });
    }
    searchParams.append("populate", "*");
    const url = `${process.env.REACT_APP_BASE_URL}/api/videos?${searchParams}&pageSize=${pagination?.pageSize || 100}&page=${pagination?.page ? pagination?.page + 1 : 1}`;
    const response = await axios.get(url);
    return response;
};

export const getVideosLatinMusicId = async (id) => {
    const url = `${process.env.REACT_APP_BASE_URL}/api/videos/${id}?populate=*`;
    const response = await axios.get(url);
    return response;
};

export const createVideosLatinMusic = async (key) => {
    const token = sessionStorage.getItem("token");
    const formData = new FormData();
    Object.entries(key).forEach(([key, value]) => {
        if (value) {
            if (key === "tags") {
                if (!value) return;
                value.map((tag) => formData.append(key, tag));
                return;
            }
            formData.append(key, value);
        }
    });
    const config = {
        method: "post",
        url: `${process.env.REACT_APP_BASE_URL}/api/videos`,
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
        },
        data: formData,
    };
    const response = await axios(config);
    return response;
};

export const editVideosLatinMusic = async (id, key) => {
    const token = sessionStorage.getItem("token");
    const formData = new FormData();
    Object.entries(key).forEach(([key, value]) => {
        if (key === "Portada" && !(value instanceof File)) return;
        if (key === "tags") {
            if (!value) return;
            value.map((tag) => formData.append(key, tag));
            return;
        }
        formData.append(key, value);
    });
    const config = {
        method: "put",
        url: `${process.env.REACT_APP_BASE_URL}/api/videos/${id}`,
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
        },
        data: formData,
    };
    const response = await axios(config);
    return response;
};

export const deleteVideosLatinMusic = async (id) => {
    const token = sessionStorage.getItem("token");
    const response = axios.delete(`${process.env.REACT_APP_BASE_URL}/api/videos/${id}`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    console.log(response);
    return response;
};

export const createVideoTeleNews = async (data) => {
    const token = sessionStorage.getItem("token");
    const config = {
        method: "post",
        url: `${process.env.REACT_APP_BASE_URL}/api/tele-revistas/create-by-link?populate=*`,
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        },

        data: JSON.stringify(data),
    };
    const response = await axios(config);
    return response;
};
export const likeNews = (id, discount = false) => {
    const token = sessionStorage.getItem("token");
    if (!token)
        return axios.put(
            `${process.env.REACT_APP_BASE_URL}/api/news-adders/like/${id}?discount=${discount}`,
            {},
            {
                headers: {
                    "Content-Type": "application/json",
                },
            },
        );
    else
        return axios.put(
            `${process.env.REACT_APP_BASE_URL}/api/news-adders/like/${id}?discount=${discount}`,
            {},
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
            },
        );
};

export const dislikeNews = (id, discount = false) => {
    const token = sessionStorage.getItem("token");
    if (!token)
        return axios.put(
            `${process.env.REACT_APP_BASE_URL}/api/news-adders/dislike/${id}?discount=${discount}`,
            {},
            {
                headers: {
                    "Content-Type": "application/json",
                },
            },
        );
    else
        return axios.put(
            `${process.env.REACT_APP_BASE_URL}/api/news-adders/dislike/${id}?discount=${discount}`,
            {},
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
            },
        );
};

export const likeChannel = (id, discount = false) => {
    return axios.put(
        `${process.env.REACT_APP_BASE_URL}/api/tv-canals/like/${id}?discount=${discount}`,
        {},
        {
            headers: {
                "Content-Type": "application/json",
            },
        },
    );
};

export const dislikeChannel = (id, discount = false) => {
    return axios.put(
        `${process.env.REACT_APP_BASE_URL}/api/tv-canals/dislike/${id}?discount=${discount}`,
        {},
        {
            headers: {
                "Content-Type": "application/json",
            },
        },
    );
};

export const getAllChanels = async (pagination) => {
    const token = sessionStorage.getItem("token");
    const url = `${process.env.REACT_APP_BASE_URL}/api/tv-canals?populate=*&pagination[pageSize]=${pagination?.pageSize || 25}&pagination[page]=${pagination?.page ? pagination?.page + 1 : 1}`;
    const response = await axios.get(url, { headers: { Authorization: `Bearer ${token}` } });
    return response;
};

export const getChanel = async (id) => {
    const token = sessionStorage.getItem("token");
    const url = `${process.env.REACT_APP_BASE_URL}/api/tv-canals/${id}?populate=*`;
    const response = await axios.get(url, { headers: { Authorization: `Bearer ${token}` } });
    return response;
};

export const createChanel = async (data) => {
    const formData = new FormData();
    const token = sessionStorage.getItem("token");
    formData.append("name", data.name);
    formData.append("description", data.description);
    formData.append("logo", data.logo);
    const config = {
        method: "post",
        url: `${process.env.REACT_APP_BASE_URL}/api/tv-canals`,
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
        },
        data: formData,
    };
    const response = await axios(config);
    return response;
};

export const updateChanel = async (id, data) => {
    const formData = new FormData();
    const token = sessionStorage.getItem("token");
    formData.append("name", data.name);
    formData.append("description", data.description);
    if (data.logo instanceof File) {
        formData.append("logo", data.logo);
    }
    const config = {
        method: "post",
        url: `${process.env.REACT_APP_BASE_URL}/api/tv-canals/${id}`,
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
        },
        data: formData,
    };
    const response = await axios(config);
    return response;
};

export const deleteChanelId = async (id) => {
    const token = sessionStorage.getItem("token");
    const url = `${process.env.REACT_APP_BASE_URL}/api/tv-canals/${id}`;
    const response = await axios.delete(url, { headers: { Authorization: `Bearer ${token}` } });
    return response;
};

export const createResource = async (data) => {
    const token = sessionStorage.getItem("token");
    const config = {
        method: "post",
        url: `${process.env.REACT_APP_BASE_URL}/api/tv-recurso/create-by-link`,
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        },
        data: JSON.stringify(data),
    };
    const response = await axios(config);
    return response;
};
export const updateResource = async (id, data, prevHeadlines) => {
    const token = sessionStorage.getItem("token");
    const excludeHeadline = prevHeadlines
        .filter((prevEl) => data.headLines.every((item) => prevEl.id !== item?.id))
        .map((el) => el.id);
    const includeHeadline = data.headLines.filter((el) => !el.id);
    const updateHeadline = data.headLines.filter((el) => el.update);
    const { headLines, link, ...payload } = data;
    const config = {
        method: "put",
        url: `${process.env.REACT_APP_BASE_URL}/api/tv-recursos/${id}`,
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        },
        data: JSON.stringify({ ...payload, excludeHeadline, includeHeadline, updateHeadline }),
    };
    const response = await axios(config);
    return response;
};

export const getAllResources = async (pagination) => {
    const token = sessionStorage.getItem("token");
    const url = `${process.env.REACT_APP_BASE_URL}/api/tv-recursos?populate=*&pagination[pageSize]=${pagination?.pageSize || 100}&pagination[page]=${pagination?.page ? pagination?.page + 1 : 1}`;
    const response = await axios.get(url, { headers: { Authorization: `Bearer ${token}` } });
    return response;
};
export const deleteResource = async (id) => {
    const token = sessionStorage.getItem("token");
    const url = `${process.env.REACT_APP_BASE_URL}/api/tv-recursos/${id}`;
    const response = await axios.delete(url, { headers: { Authorization: `Bearer ${token}` } });
    return response;
};
export const getResource = async (id) => {
    const token = sessionStorage.getItem("token");
    const url = `${process.env.REACT_APP_BASE_URL}/api/tv-recursos/${id}?populate=*`;
    const response = await axios.get(url, { headers: { Authorization: `Bearer ${token}` } });
    return response;
};
export const getChanelPlaylist = async (id) => {
    const token = sessionStorage.getItem("token");
    const url = `${process.env.REACT_APP_BASE_URL}/api/tv-playlists/${id}?populate=*`;
    const response = await axios.get(url, { headers: { Authorization: `Bearer ${token}` } });
    return response;
};

export const createChanelPlaylist = async (data) => {
    const token = sessionStorage.getItem("token");
    const body = {
        playlistInput: {
            name: data.name,
            date: data.date,
            tv_canal: data.tv_canal,
        },
        tv_recursos: data.tv_recursos.map((el, index) => {
            return { id: el, order: index + 1 };
        }),
    };
    const config = {
        method: "post",
        url: `${process.env.REACT_APP_BASE_URL}/api/tv-playlists`,
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        },
        data: JSON.stringify({ ...body }),
    };
    const response = await axios(config);
    return response;
};

export const deleteChanelPlaylist = async (id) => {
    const token = sessionStorage.getItem("token");
    const url = `${process.env.REACT_APP_BASE_URL}/api/tv-playlists/${id}`;
    const response = await axios.delete(url, { headers: { Authorization: `Bearer ${token}` } });
    return response;
};

export const editChanelPlaylist = async (id, data) => {
    const token = sessionStorage.getItem("token");
    const recursos = data.tv_recursos.map((el, index) => {
        return { id: el, order: index + 1 };
    });
    const { tv_recursos, ...elements } = data;
    const body = { recursos, ...elements };
    const config = {
        method: "put",
        url: `${process.env.REACT_APP_BASE_URL}/api/tv-playlists/${id}`,
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        },
        data: JSON.stringify({ ...body }),
    };
    const response = await axios(config);
    return response;
};
export const getFakeUserLikes = async () => {
    const endpointUrl = `${process.env.REACT_APP_BASE_URL}/api/news-adders?filters[state]=farandula&sort=updatedAt:desc&pagination[limit]=4&populate=*`;
    const axiosResponse = await axios.get(endpointUrl);
    return axiosResponse.data.data;
};
export const getFakeUserReadedNews = async () => {
    const endpointUrl = `${process.env.REACT_APP_BASE_URL}/api/news-adders?filters[state]=aprobada&sort=updatedAt:desc&pagination[limit]=4&populate=*`;
    const axiosResponse = await axios.get(endpointUrl);
    return axiosResponse.data.data;
};
export const getFakeUserComments = async () => {
    return [];
};
export const getFakeUserMusic = async () => {
    const endpointUrl = `${process.env.REACT_APP_BASE_URL}/api/videos?tipo_video=Musica_latina`;
    const axiosResponse = await axios.get(endpointUrl);
    return axiosResponse.data.data;
};
export const getCommentsNews = async (key) => {
    const [newsId, page, type] = key;
    const url = `${process.env.REACT_APP_BASE_URL}/api/comment/get?filters[${type}]=${newsId}&pagination[page]=${page}&pagination[pageSize]=25&filters[$and][0][reply][id][$null]=true&sort[0]=updatedAt:desc`;
    const response = await axios.get(url);
    return response.data;
};
export const getCommentsId = async (id) => {
    const url = `${process.env.REACT_APP_BASE_URL}/api/comment/get?filters[id]=${id}`;
    const response = await axios.get(url);
    return response.data;
};
export const postCommentNews = async (key, message) => {
    const url = `${process.env.REACT_APP_BASE_URL}/api/comments`;
    const body = {
        ...key,
        message,
    };
    const token = sessionStorage.getItem("token");
    const response = await axios.post(url, body, {
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        },
    });
    return response?.data;
};
export const postResponsesNews = async (key, message, reply) => {
    const url = `${process.env.REACT_APP_BASE_URL}/api/comments`;
    const body = {
        ...key,
        message,
        reply,
    };
    const token = sessionStorage.getItem("token");
    const response = await axios.post(url, body, {
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        },
    });
    return response?.data;
};
export const deleteCommentNews = async (id) => {
    const url = `${process.env.REACT_APP_BASE_URL}/api/comment/remove/${id}`;
    const token = sessionStorage.getItem("token");
    const response = await axios.delete(url, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    return response?.data;
};
export const editCommentNews = async (id, message) => {
    const url = `${process.env.REACT_APP_BASE_URL}/api/comment/edit/${id}`;
    const body = {
        message,
    };
    const token = sessionStorage.getItem("token");
    const response = await axios.put(url, body, {
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        },
    });
    return response?.data;
};
export const getFakeComunityData = async (page) => {
    const endpointUrl = `${process.env.REACT_APP_BASE_URL}/api/news-adders?filters[state]=aprobada&sort=updatedAt:desc&pagination[pageSize]=25&pagination[page]=${page}&populate=*`;
    const axiosResponse = await axios.get(endpointUrl);
    return axiosResponse.data;
};
export const getComunityData = async () => {
    const url = `${process.env.REACT_APP_BASE_URL}/api/comunity?populate=*`;
    const axiosResponse = await axios.get(url);
    return axiosResponse.data;
};
export const getPanelEvents = async (pagination) => {
    const { page, pageSize } = pagination;
    const url = `${process.env.REACT_APP_BASE_URL}/api/events?populate=*&pagination[pageSize]=${pageSize || 100}&pagination[page]=${page ? page + 1 : 1}`;
    const axiosResponse = await axios.get(url);
    return axiosResponse?.data;
};
export const postEventDashboard = async (key) => {
    const url = `${process.env.REACT_APP_BASE_URL}/api/events`;
    const token = sessionStorage.getItem("token");
    const formData = new FormData();
    Object.entries(key).forEach(([key, value]) => {
        if (key === "artist" && value) {
            formData.append(key, value.id);
            return;
        }
        if (value) formData.append(key, value);
    });
    const response = await axios.post(url, formData, {
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
        },
    });
    return response?.data;
};
export const getEventsHome = async (page) => {
    const dateNow = new Date().toISOString();
    const url = `${process.env.REACT_APP_BASE_URL}/api/events?populate=*&pagination[pageSize]=100&pagination[page]=${page}&filters[end_date][$gte]=${dateNow}`;
    const axiosResponse = await axios.get(url);
    return axiosResponse?.data;
};

export const getConcertsType = async (page, type) => {
    const dateNow = new Date().toISOString();
    const url = `${process.env.REACT_APP_BASE_URL}/api/events?populate=*&pagination[pageSize]=100&pagination[page]=${page}&filters[end_date][$gte]=${dateNow}&filters[event_type][name]=${type}`;
    //const axiosResponse = await axios.get(url);
    const response = fetch(url);
    return response;
    //return axiosResponse?.data;
};
export const deleteEventsConcert = async (id) => {
    const url = `${process.env.REACT_APP_BASE_URL}/api/events/${id}`;
    const token = sessionStorage.getItem("token");
    const response = await axios.delete(url, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    return response;
};
export const putEventDashboard = async (key, id) => {
    const url = `${process.env.REACT_APP_BASE_URL}/api/events/${id}`;
    const token = sessionStorage.getItem("token");
    const formData = new FormData();
    Object.entries(key).forEach(([key, value]) => {
        if (key === "image" && !(value instanceof File)) return;
        if (key === "artist" && value) {
            formData.append(key, value.id);
            return;
        }
        if (value) formData.append(key, value);
    });
    const response = await axios.put(url, formData, {
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
        },
    });
    return response?.data;
};
export const getPanelEventsPerID = async (id) => {
    const url = `${process.env.REACT_APP_BASE_URL}/api/events/${id}?populate=*`;
    const token = sessionStorage.getItem("token");
    const axiosResponse = await axios.get(url, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    return axiosResponse?.data;
};

export const getAllowedInterests = async (key) => {
    const url = `${process.env.REACT_APP_BASE_URL}${key}`;
    const token = sessionStorage.getItem("token");
    const axiosResponse = await axios.get(url, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });

    return axiosResponse.data;
};

export const putFanaticProfileData = async (key, { arg }) => {
    const [, role, token] = key;
    if (arg.image) {
        if (!(arg.image instanceof File)) delete arg["image"];
    }
    if (arg.cover) {
        if (!(arg.cover instanceof File)) delete arg["cover"];
    }
    if (arg.poster) {
        if (!(arg.poster instanceof File)) delete arg["poster"];
    }
    const requestOptions = {
        headers: { Authorization: `Bearer ${token}` },
    };
    switch (role) {
        case 3: {
            const url = `${process.env.REACT_APP_BASE_URL}/api/artist/update/me`;
            const response = await axios.putForm(url, arg, requestOptions);
            const artist = response.data.data.artist;
            const metakeys = ["id", "type", "createdAt", "updatedAt", "publishedAt", "fans"];
            metakeys.forEach((metaKey) => {
                delete artist[metaKey];
            });
            artist.cover = artist?.cover?.url;
            artist.poster = artist?.poster?.url;
            artist.image = response.data.data.user?.image?.url;
            return artist;
        }

        case 4: {
            const url = `${process.env.REACT_APP_BASE_URL}/api/artist/update/me`;
            const response = await axios.putForm(url, arg, requestOptions);
            const artist = response.data.data.artist;
            const metakeys = ["id", "type", "createdAt", "updatedAt", "publishedAt", "fans"];
            metakeys.forEach((metaKey) => {
                delete artist[metaKey];
            });
            artist.cover = artist?.cover?.url;
            artist.image = response.data.data.user?.image?.url;
            return artist;
        }
    }
};

export const getReviews = async (pagination) => {
    const url = `${process.env.REACT_APP_BASE_URL}/api/reviews?populate=*&pagination[pageSize]=${pagination?.pageSize || 100}&pagination[page]=${pagination?.page ? pagination?.page + 1 : 1}`;
    const response = await axios.get(url)
    return response
}
export const getReviewId = async (id) => {
    const url = `${process.env.REACT_APP_BASE_URL}/api/reviews/${id}?populate=*`;
    const response = await axios.get(url)
    return response
}
export const postReview = async (data) => {
    const url = `${process.env.REACT_APP_BASE_URL}/api/reviews`;
    const token = sessionStorage.getItem("token");
    const formData = new FormData()
    Object.entries(data).forEach(([key,value]) => {
        if (!value) return 
        formData.append(key, value)
    })
    const response = await axios.post(url, formData, {
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
        },
    })
    return response;
}
export const deleteReview = async (id) => {
    const url = `${process.env.REACT_APP_BASE_URL}/api/reviews/${id}`;
    const token = sessionStorage.getItem("token");
    const response = await axios.delete(url, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
    return response
}
export const putReview = async (id, data) => {
    const url = `${process.env.REACT_APP_BASE_URL}/api/reviews/${id}`;
    const token = sessionStorage.getItem("token");
    const formData = new FormData()
    Object.entries(data).forEach(([key,value]) => {
        if (!value) return
        if (key === "image" && !(value instanceof File)) return
        formData.append(key, value)
    })
    const response = await axios.post(url, formData, {
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
        },
    })
    return response;
}

