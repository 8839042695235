import { useContext } from "react";

import { Box, IconButton, Typography } from "@mui/material";
import SitoImage from "sito-image";
import DeleteIcon from "@mui/icons-material/Delete";
import { DELETE_SHOP_CAR_CROWD_FUNDING } from "../../actions/constants";

const ItemShopCar = ({ product, actionDelete, contextAction }) => {
    const { id, name, price, photo, cant } = product;

    const dispatch = useContext(contextAction);

    const deleteProduct = () => {
        dispatch({
            type: actionDelete,
            item: product,
        });
    };

    return (
        <Box
            sx={{
                display: "flex",
                width: "100%",
                height: "4rem",
                paddingTop: "0.75rem",
                paddingBottom: "0.75rem",
                paddingLeft: "0.4rem",
                borderRadius: "10px",
                "&:hover": {
                    transition: "transform 500ms ease !important",
                    backgroundColor: "#7c7b7b33",
                },
            }}
        >
            <Box
                sx={{
                    width: "40px",
                    height: "40px",
                    borderRadius: "50%",
                    marginRight: "0.4rem",
                }}
            >
                <SitoImage
                    sx={{
                        width: "100%",
                        height: "100%",
                        borderRadius: "50%",
                        objectFit: "cover",
                    }}
                    src={
                        photo
                            ? `${process.env.REACT_APP_BASE_URL}${photo}`
                            : `https://loremflickr.com/320/240/${
                                  ["brazil", "paris", "canada"][Math.floor(Math.random() * 3)]
                              }/all`
                    }
                    alt={id}
                />
            </Box>

            <Box
                sx={{
                    maxWidth: "150px",
                }}
            >
                <Box display="flex" alignItems="left" justifyContent="space-between">
                    <Typography variant="h9">{name}</Typography>
                </Box>

                <Box display="flex" alignItems="right" justifyContent="space-between">
                    <Typography
                        sx={{
                            fontSize: "0.7rem",
                        }}
                    >
                        {actionDelete === DELETE_SHOP_CAR_CROWD_FUNDING
                            ? `$${cant}`
                            : `${cant} X $${price}`}
                    </Typography>
                </Box>
            </Box>

            <IconButton
                onClick={deleteProduct}
                sx={{
                    marginRight: "0",
                    marginLeft: "auto",
                    marginTop: "auto",
                    marginBottom: "auto",
                    color: "#f15a24",
                }}
            >
                <DeleteIcon />
            </IconButton>
        </Box>
    );
};

export default ItemShopCar;
