//material components
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import HomeIcon from "@mui/icons-material/Home";

//react-router
import { useNavigate } from "react-router-dom";

const AccessDenied = () => {
    const navigate = useNavigate();

    const navigateToHome = () => navigate("/");
    return (
        <Box pt="100px">
            <Stack alignItems="center" rowGap={1}>
                <Typography component="h1" variant="h4" color="red">
                    Acceso denegado.
                </Typography>
                <Typography>El usuario autenticado no posee acceso a esta página.</Typography>
                <Button variant="outlined" startIcon={<HomeIcon />} onClick={navigateToHome}>
                    Regresar al inicio
                </Button>
            </Stack>
        </Box>
    );
};

export default AccessDenied;
