//react
import React, { useEffect, useState } from "react";

//@mui/material
import Modal from "@mui/material/Modal";
import { Box, Typography, useTheme } from "@mui/material";

//component
import BottomBar from "../../ChatComponent/SpaceToTalk/BottomBar";
import SpaceConversation from "../SpaceConversation/SpaceConversation";

//SitoImage
import SitoImage from "sito-image";

//assets/images
import BodyRobot from "../../../assets/images/body_robot-removebg.png";

//hooks
import { floatChat, sendMessage, sendMessageNotAuthenticated } from "../../../hooks/useFetchChat";

//icon
import Brightness1Icon from "@mui/icons-material/Brightness1";

//context
import { useLanguage } from "../../../context/LanguageProvider";
import { useSelector } from "react-redux";

const FloatChatModal = ({ open }) => {
    const theme = useTheme();
    const { languageState } = useLanguage();
    const userToken = sessionStorage.getItem("token");
    const [newMessage, setNewMessage] = useState("");

    const [loading, setLoading] = useState(false);
    const [scroll, setScroll] = useState(false);
    const user = useSelector((state) => state.user);

    //console.log("user");
    const style = {
        position: "absolute",
        bottom: "90px",
        right: "70px",
        width: { xs: "300px", lg: "320px" },
        bgcolor: "background.paper",
        boxShadow: 24,
        borderRadius: "10px",
    };
    const styleModal = {
        "& .MuiBackdrop-root": {
            opacity: "0 !important",
        },
    };

    const additionalText = {
        parts: [
            {
                text: languageState.texts.FloatChat.AdditionalText.Parts,
            },
        ],
        role: languageState.texts.FloatChat.AdditionalText.Role,
    };

    const [messageChat, setMessageChat] = useState([additionalText]);

    const fetchData = async () => {
        try {
            const resp = await floatChat(`${process.env.REACT_APP_BASE_URL}/api/ia-mensajes`);
            setMessageChat([additionalText, ...resp.data]);
            //console.log("detailsGroup", resp);
        } catch (error) {}
    };
    //console.log("messageChat", messageChat);

    const handleSendMessage = async () => {
        try {
            let msg = newMessage;
            setNewMessage("");
            setLoading(true);
            setScroll(true);
            if (user?.user_id) {
                const formdata = new FormData();
                formdata.append("message", msg);
                const resp = await sendMessage(
                    `${process.env.REACT_APP_BASE_URL}/api/ia-mensajes/send`,
                    formdata,
                );
                setMessageChat([additionalText, ...resp.data]);
            } else {
                const data = JSON.stringify({ message: msg, history: messageChat });

                const resp = await sendMessageNotAuthenticated(
                    `${process.env.REACT_APP_BASE_URL}/api/ia-mensajes/public-send`,
                    data,
                );

                setMessageChat(resp.data);
            }

            setLoading(false);
            setScroll(false);
        } catch (error) {
            setLoading(false);
            setScroll(false);
        }
    };
    useEffect(() => {
        fetchData();
    }, [userToken]);

    return (
        <>
            <Modal
                sx={{ ...styleModal }}
                open={open}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
            >
                <>
                    <Box sx={{ ...style, height: 400 }}>
                        <Box
                            sx={{
                                height: 80,
                                background: theme.chat.background.paper,
                                borderTopLeftRadius: "10px",
                                borderTopRightRadius: "10px",
                                position: "relative",
                            }}
                        >
                            <Box
                                sx={{
                                    width: "100%",
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    position: "absolute",
                                    justifyContent: "center",
                                    marginTop: "20px",
                                }}
                            >
                                <Typography sx={{ fontSize: "14px" }}>
                                    {languageState.texts.FloatChat.TopText.Title}
                                </Typography>
                                <Typography
                                    sx={{ fontSize: "12px", display: "flex", alignItems: "center" }}
                                >
                                    {" "}
                                    <Brightness1Icon
                                        sx={{
                                            width: "8px",
                                            height: "8px",
                                            color: "green",
                                            marginRight: "2px",
                                        }}
                                    />{" "}
                                    {languageState.texts.FloatChat.TopText.Subtext}
                                </Typography>
                            </Box>
                            <Box>
                                <SitoImage
                                    sx={{
                                        position: "absolute",
                                        height: "70px",
                                        width: "70px",
                                        bottom: "0",
                                        right: "0",
                                    }}
                                    src={BodyRobot}
                                />
                            </Box>
                        </Box>
                        <Box
                            sx={{
                                height: 320,
                                position: "relative",
                                paddingBottom: { xs: "75px", lg: "60px" },
                            }}
                        >
                            <SpaceConversation
                                messageChat={messageChat}
                                loading={loading}
                                scroll={scroll}
                            />
                            <BottomBar
                                setNewMessage={setNewMessage}
                                newMessage={newMessage}
                                handleSendMessage={handleSendMessage}
                            />
                        </Box>
                    </Box>
                </>
            </Modal>
        </>
    );
};

export default FloatChatModal;
