import { 
    getEventsHome,
    getConcertsType,
    getReviews,
    getReviewId,
} from "../actions";

const fakeData = [
    {
        id: 1,
        title: "Despacito",
        album: "Vida",
        disk: "Sensation",
        description: "Uno de los mayores éxitos globales de reguetón, interpretado por Luis Fonsi y Daddy Yankee.",
        img: "https://th.bing.com/th/id/R.5251a363df250cb2465a439e08cf890b?rik=O9H7T5ih5TD9aQ&pid=ImgRaw&r=0"
    },
    {
        id: 2,
        title: "Shape of You",
        album: "Divide",
        disk: "Multiple",
        description: "Popular canción de Ed Sheeran que mezcla pop con influencias de dancehall.",
        img: "https://th.bing.com/th/id/R.5251a363df250cb2465a439e08cf890b?rik=O9H7T5ih5TD9aQ&pid=ImgRaw&r=0"
    },
    {
        id: 3,
        title: "Blinding Lights",
        album: "After Hours",
        disk: "Multiple",
        description: "Canción de The Weeknd con un estilo retro y sintetizadores al estilo de los 80s.",
        img: "https://th.bing.com/th/id/R.5251a363df250cb2465a439e08cf890b?rik=O9H7T5ih5TD9aQ&pid=ImgRaw&r=0"
    },
    {
        id: 4,
        title: "Dance Monkey",
        album: "The Kids Are Coming",
        disk: "Lorde",
        description: "Éxito mundial de Tones and I, caracterizado por su ritmo pegajoso y única voz.",
        img: "https://th.bing.com/th/id/R.5251a363df250cb2465a439e08cf890b?rik=O9H7T5ih5TD9aQ&pid=ImgRaw&r=0"
    },
    {
        id: 5,
        title: "Rockstar",
        album: "Beerbongs & Bentleys",
        disk: "Multiple",
        description: "Canción de Post Malone con un estilo relajado que combina rap y melodías pop.",
        img: "https://th.bing.com/th/id/R.5251a363df250cb2465a439e08cf890b?rik=O9H7T5ih5TD9aQ&pid=ImgRaw&r=0"
    },
    {
        id: 6,
        title: "Señorita",
        album: "Romance",
        disk: "Lorde",
        description: "Dúo romántico de Shawn Mendes y Camila Cabello que se volvió viral en redes.",
        img: "https://th.bing.com/th/id/R.5251a363df250cb2465a439e08cf890b?rik=O9H7T5ih5TD9aQ&pid=ImgRaw&r=0"
    },
    {
        id: 7,
        title: "Old Town Road",
        album: "7",
        disk: "Lorde",
        description: "Éxito de Lil Nas X que combina música country y trap.",
        img: "https://th.bing.com/th/id/R.5251a363df250cb2465a439e08cf890b?rik=O9H7T5ih5TD9aQ&pid=ImgRaw&r=0"
    },
    {
        id: 8,
        title: "Havana",
        album: "Camila",
        disk: "Lorde",
        description: "Éxito de Camila Cabello con influencia latina y colaboraciones destacadas.",
        img: "https://th.bing.com/th/id/R.5251a363df250cb2465a439e08cf890b?rik=O9H7T5ih5TD9aQ&pid=ImgRaw&r=0"
    },
    {
        id: 9,
        title: "Thank U, Next",
        album: "Thank U, Next",
        disk: "Lorde",
        description: "Hit de Ariana Grande que celebra la superación personal y las relaciones pasadas.",
        img: "https://th.bing.com/th/id/R.5251a363df250cb2465a439e08cf890b?rik=O9H7T5ih5TD9aQ&pid=ImgRaw&r=0"
    },
    {
        id: 10,
        title: "Bad Guy",
        album: "When We All Fall Asleep, Where Do We Go?",
        disk: "Lorde",
        description: "Canción de Billie Eilish con un estilo único y una base rítmica pegajosa.",
        img: "https://th.bing.com/th/id/R.5251a363df250cb2465a439e08cf890b?rik=O9H7T5ih5TD9aQ&pid=ImgRaw&r=0"
    }
];

export const concertLoader = ({ request }) => {
    const location = new URL(request.url);
    const page = location.searchParams.get("page") || 1;
    const response = getEventsHome(page);
    const resConcierto = getConcertsType(1, "Concierto")
        .then((res) => res.json())
        .then((body) => body);
    const resFestivales = getConcertsType(1, "Festivales")
        .then((res) => res.json())
        .then((body) => body);
    const resTeatros = getConcertsType(1, "Teatros")
        .then((res) => res.json())
        .then((body) => body);
    const resSalas = getConcertsType(1, "Salas")
        .then((res) => res.json())
        .then((body) => body);
    return {
        eventData: response,
        eventConcierto: resConcierto,
        eventFestivales: resFestivales,
        eventTeatros: resTeatros,
        eventSalas: resSalas,
    };
};

export const reviewLoader = () => {
    const response = getReviews()
    return { reviewData: response }
}

export const reviewDetailLoader = ({ request, params }) => {
    const { id } = params
    const response = getReviewId(id)
    return { reviewData: response }
}