import { a11yProps } from "../../utils/functions";
import Tab from "@mui/material/Tab";
import CustomTabPanel from "./CustomTabPanel";
import { Box, Tabs } from "@mui/material";
import { useLanguage } from "../../context/LanguageProvider";
import { useState } from "react";
import ProductTab from "./tabs/ProductTab";
import TicketTab from "./tabs/TicketTab";
import CrowdFundingTab from "./tabs/CrowdFundingTab";

const TabPanelCar = () => {
    const { languageState } = useLanguage();

    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label={languageState.texts.ShopCar.tabs.product} {...a11yProps(0)} />
                    <Tab label={languageState.texts.ShopCar.tabs.ticket} {...a11yProps(1)} />
                    <Tab label={languageState.texts.ShopCar.tabs.crowdFunding} {...a11yProps(2)} />
                </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
                <ProductTab />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
                <TicketTab />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
                <CrowdFundingTab />
            </CustomTabPanel>
        </Box>
    );
};

export default TabPanelCar;
