//material components
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";

//hooks
import { useDispatchActivateSearch } from "../../context/ActivateSearchProvider";

const SearchIconButton = () => {
    const dispatchActivateSearch = useDispatchActivateSearch();
    const handleActivateSearch = () => {
        dispatchActivateSearch({
            type: "turnOn",
        });
    };
    return (
        <IconButton color="primary" onClick={handleActivateSearch}>
            <SearchIcon />
        </IconButton>
    );
};

export default SearchIconButton;
