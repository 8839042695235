import { Badge, IconButton, ListItemIcon, ListItemText } from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import React, { useContext, useState } from "react";
import { NotificationContext } from "../../context/NotificationProvider";
import MenuItem from "@mui/material/MenuItem";
import CircleNotificationsIcon from "@mui/icons-material/CircleNotifications";
import Menu from "@mui/material/Menu";

const NotificationMenu = () => {
    const { notificationState } = useContext(NotificationContext);

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        if (notificationState.messages.length > 0) {
            setAnchorEl(event.currentTarget);
        }
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <IconButton
                id="basic-button-notification"
                color="primary"
                aria-controls={open ? "notification-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
            >
                <Badge badgeContent={notificationState.messages?.length} color="secondary">
                    <NotificationsIcon />
                </Badge>
            </IconButton>
            <Menu
                id="notification-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    "aria-labelledby": "basic-button-notification",
                }}
            >
                {notificationState.messages?.map((e) => {
                    return (
                        <MenuItem>
                            <ListItemIcon
                                sx={{
                                    marginRight: "0.4rem",
                                }}
                            >
                                <CircleNotificationsIcon fontSize="large" color="primary" />
                            </ListItemIcon>
                            <ListItemText primary={e.title} secondary={e.body} />
                        </MenuItem>
                    );
                })}
            </Menu>
        </>
    );
};

export default NotificationMenu;
