/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from "react";

// @mui/material
import { ThemeProvider } from "@mui/material/styles";

// @mui components
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Box, CssBaseline, Snackbar } from "@mui/material";
import { FirebaseConext } from "./context/FirebaseConext";
import { NotificationContext } from "./context/NotificationProvider";
import Alert from "@mui/material/Alert";
import useOnclickOutside from "react-cool-onclickoutside";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { AlertTitle } from "@mui/lab";

// themes
import dark from "./assets/theme/dark";
import light from "./assets/theme/light";

// contexts
import { useMode } from "./context/ModeProvider";
import PlayerProvider from "./context/PlayerProvider";
// layouts
import { onMessage } from "firebase/messaging";
import "react-toastify/dist/ReactToastify.css";

import { useSelector } from "react-redux";
import { THEME_LIGHT } from "./constants/all.js";
import AppRoutes from "./routes/AppRoutes.js";

const App = () => {
    const theme = useSelector((state) => state.useThemePage);

    const [open, setOpen] = useState(false);

    const firebase = useContext(FirebaseConext);
    const context = useContext(NotificationContext);

    const handleClose = () => {
        context.setNotificationState({
            type: "set",
            ntype: "success",
            visible: false,
            message: {},
        });
        setOpen(false);
    };

    const ref = useOnclickOutside(() => {
        handleClose();
    });

    const messaging = onMessage(firebase, (message) => {
        context.setNotificationState({
            type: "set",
            ntype: "success",
            visible: true,
            message: message.notification,
        });
        setOpen(true);
    });

    useEffect(() => {
        document.body.style.overflowX = "hidden";
        document.body.style.transition = "all 200ms ease";
    }, []);

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <ThemeProvider theme={theme == THEME_LIGHT ? light : dark}>
                <PlayerProvider>
                    <CssBaseline />
                    <div>
                        <Snackbar
                            open={open}
                            sx={{
                                zIndex: 9999999,
                            }}
                            autoHideDuration={6000}
                            onClose={handleClose}
                            anchorOrigin={{ horizontal: "right", vertical: "top" }}
                        >
                            <Alert
                                onClose={handleClose}
                                severity={context?.notificationState.type}
                                icon={<NotificationsIcon />}
                            >
                                <AlertTitle>{context?.notificationState.message.title}</AlertTitle>
                                {context?.notificationState.message.body}
                            </Alert>
                        </Snackbar>
                    </div>
                    <Box>
                        <AppRoutes />
                    </Box>
                </PlayerProvider>
            </ThemeProvider>
        </LocalizationProvider>
    );
};

export default App;
