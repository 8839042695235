export const ADD_SHOP_CAR_PRODUCT = "ADD_SHOP_CAR_PRODUCT";
export const ADD_SHOP_CAR_TICKET = "ADD_SHOP_CAR_TICKET";
export const ADD_SHOP_CAR_CROWD_FUNDING = "ADD_SHOP_CAR_CROWD_FUNDING";

export const DELETE_SHOP_CAR_PRODUCT = "DELETE_SHOP_CAR_PRODUCT";
export const DELETE_SHOP_CAR_TICKET = "DELETE_SHOP_CAR_TICKET";
export const DELETE_SHOP_CAR_CROWD_FUNDING = "DELETE_SHOP_CAR_CROWD_FUNDING";

export const RESET_SHOP_CAR_PRODUCT = "RESET_SHOP_CAR_PRODUCT";
export const RESET_SHOP_CAR_TICKET = "RESET_SHOP_CAR_TICKET";
export const RESET_SHOP_CAR_CROWD_FUNDING = "RESET_SHOP_CAR_CROWD_FUNDING";
export const RESET_SHOP_CAR_ALL = "RESET_SHOP_CAR_ALL";

export const ADD_ARRAY_PRODUCTS_CAR_PRODUCT = "ADD_ARRAY_PRODUCTS_CAR_PRODUCT";
export const ADD_ARRAY_PRODUCTS_CAR_TICKET = "ADD_ARRAY_PRODUCTS_CAR_TICKET";
export const ADD_ARRAY_PRODUCTS_CAR_CROWD_FUNDING = "ADD_ARRAY_PRODUCTS_CAR_CROWD_FUNDING";

export const BUYING_ACTION = "BUYING_ACTION";
export const FINISHED_BUYING_ACTION = "FINISHED_BUYING_ACTION";

export const TYPE_PRODUCT = "producto";
export const TYPE_CROWD_FUNDING = "crowdfunding";
export const TYPE_TICKET = "evento";
