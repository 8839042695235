import { createSlice } from "@reduxjs/toolkit";
import {
    ENABLE_CHANGE_THEME,
    DISABLE_CHANGE_THEME,
    THEME_DARK,
    THEME_LIGHT,
    ONE_CHANGE_THEME,
} from "../../constants/all";

const initialNews = localStorage.getItem("news");
const initialChannel = localStorage.getItem("channel");
let useThemePage = localStorage.getItem("theme");
let changeTheme = localStorage.getItem("change");

if (changeTheme == ONE_CHANGE_THEME) {
    useThemePage = THEME_DARK;
    changeTheme = ENABLE_CHANGE_THEME;
    localStorage.setItem("theme", THEME_DARK);
    localStorage.setItem("change", ENABLE_CHANGE_THEME);
}

const initialState = {
    user: null,
    useThemePage: useThemePage ?? THEME_DARK,
    changeTheme: changeTheme ?? ENABLE_CHANGE_THEME,
    news: initialNews ? JSON.parse(initialNews) : {},
    channel: initialChannel ? JSON.parse(initialChannel) : {},
};

const userSlice = createSlice({
    name: "userSlice",
    initialState,
    reducers: {
        changeThemeAction(state, action) {
            if (state.changeTheme != DISABLE_CHANGE_THEME) {
                state.useThemePage = state.useThemePage == THEME_DARK ? THEME_LIGHT : THEME_DARK;
                state.changeTheme = action.payload.type;
                if (action.payload.type != ONE_CHANGE_THEME)
                    localStorage.setItem("theme", state.useThemePage);
                localStorage.setItem("change", action.payload.type);
            }
        },

        signIn: (state, action) => {
            state.user = { ...action.payload };
        },

        signOut: (state) => {
            state.user = null;
        },

        likeNewsAction: (state, action) => {
            state.news[action.payload.id] = state.news[action.payload.id] == "L" ? undefined : "L";
            localStorage.setItem("news", JSON.stringify(state.news));
        },

        dislikeNewsAction: (state, action) => {
            state.news[action.payload.id] = state.news[action.payload.id] == "D" ? undefined : "D";
            localStorage.setItem("news", JSON.stringify(state.news));
        },

        likeChannelAction: (state, action) => {
            state.channel[action.payload.id] =
                state.channel[action.payload.id] == "L" ? undefined : "L";
            localStorage.setItem("channel", JSON.stringify(state.channel));
        },

        dislikeChannelAction: (state, action) => {
            state.channel[action.payload.id] =
                state.channel[action.payload.id] == "D" ? undefined : "D";
            localStorage.setItem("channel", JSON.stringify(state.channel));
        },
    },
});

export const {
    signIn,
    signOut,
    likeNewsAction,
    dislikeNewsAction,
    likeChannelAction,
    dislikeChannelAction,
    changeThemeAction,
} = userSlice.actions;
export default userSlice.reducer;
