//@mui/material
import { Box, IconButton, /*ListItemIcon, ListItemText,*/ TextField, useTheme } from "@mui/material";
//import Menu from "@mui/material/Menu";
//import MenuItem from "@mui/material/MenuItem";

import React, { useEffect, useRef, useState } from "react";

//icons
import InsertEmoticonIcon from "@mui/icons-material/InsertEmoticon";
//import AddIcon from "@mui/icons-material/Add";
//import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
//import FilterIcon from "@mui/icons-material/Filter";
//import DescriptionIcon from "@mui/icons-material/Description";
//import KeyboardVoiceIcon from "@mui/icons-material/KeyboardVoice";
import SendIcon from "@mui/icons-material/Send";

//context
import { useLanguage } from "../../../context/LanguageProvider";

//EmojiPicker
import Emojis from "../EmojiPicker/Emojis";

const BottomBar = ({ newMessage, setNewMessage, handleSendMessage }) => {
    const theme = useTheme();
    const { languageState } = useLanguage();
    //const [anchorEl, setAnchorEl] = useState(null);
    const [inputHeight, setInputHeight] = useState("auto");
    const inputRef = useRef(null);
    const [showEmojis, setShowEmojis] = useState(false);
    const [cursorPosition, setCursorPosition] = useState();

    /*const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };*/

    const pickEmoji = (e, callback) => {
        const emoji = e.emoji;
        const ref = inputRef.current;
        ref.focus();
        const start = newMessage.substring(0, ref.selectionStart);
        const end = newMessage.substring(ref.selectionEnd);
        const text = start + emoji + end;
        setNewMessage(text);
        const newCursorPosition = start.length + emoji.length;
        setCursorPosition(newCursorPosition);
    };
    const handleMessageChange = (event) => {
        setNewMessage(event.target.value);
        setInputHeight(event.target.scrollHeight);
        setCursorPosition(event.target.selectionStart);
    };

    const handleShowEmojis = (event) => {
        setShowEmojis(!showEmojis);
        if (inputRef.current) {
            inputRef.current.focus();
        }
    };

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.selectionStart = cursorPosition;
            inputRef.current.selectionEnd = cursorPosition;
        }
    }, [cursorPosition]);

    return (
        <>
            <Box
                sx={{
                    background: theme.chat.background.paper,
                    position: "absolute",
                    bottom: "0px",
                    width: "100%",
                    minHeight: 60,
                    //height: { xs: inputHeight + 55, lg: inputHeight + 40 },
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-around",
                    flexDirection: "column",
                }}
            >
                {showEmojis && (
                    <Box
                        sx={{
                            width: "100%",
                            zIndex: 999999,
                            position: "relative",
                            top: "0px",
                            marginTop: "-320px",
                        }}
                    >
                        <Emojis pickEmoji={pickEmoji} />
                    </Box>
                )}

                <Box sx={{ display: "flex", width: "100%", justifyContent: "space-around" }}>
                    <IconButton sx={{ color: "gray" }}>
                        <InsertEmoticonIcon onClick={handleShowEmojis} />
                    </IconButton>

                    {/*<Box>
                <IconButton
                    id="basic-button-bar"
                    aria-controls={open ? "basic-menu-bar" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    onClick={handleClick}
                    sx={{ color: "gray" }}
                >
                    <AddIcon />
                </IconButton>
                <Menu
                    id="basic-menu-bar"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                        "aria-labelledby": "basic-button-bar",
                    }}
                    style={{ zIndex: "99999" }}
                >
                    <MenuItem onClick={handleClose}>
                        <ListItemIcon>
                            <DescriptionIcon fontSize="small" color="primary" />
                        </ListItemIcon>
                        <ListItemText>{languageState.texts.Chat.MessageOptions.Document}</ListItemText>
                    </MenuItem>
                    <MenuItem onClick={handleClose}>
                        <ListItemIcon>
                            <FilterIcon fontSize="small" color="primary" />
                        </ListItemIcon>
                        <ListItemText>{languageState.texts.Chat.MessageOptions.Photo}</ListItemText>
                    </MenuItem>
                    <MenuItem onClick={handleClose}>
                        <ListItemIcon>
                            <PhotoCameraIcon fontSize="small" color="primary" />
                        </ListItemIcon>
                        <ListItemText>{languageState.texts.Chat.MessageOptions.Camera}</ListItemText>
                    </MenuItem>
                    <MenuItem onClick={handleClose}>
                        <ListItemIcon>
                            <KeyboardVoiceIcon fontSize="small" color="primary" />
                        </ListItemIcon>
                        <ListItemText>{languageState.texts.Chat.MessageOptions.Audio}</ListItemText>
                    </MenuItem>
                </Menu>
            </Box>*/}

                    <TextField
                        ref={inputRef}
                        multiline
                        value={newMessage}
                        onChange={handleMessageChange}
                        variant="outlined"
                        placeholder={languageState.texts.Chat.ImputMessage.Placeholder}
                        autoFocus
                        sx={{
                            width: "75%",
                            background: theme.palette.background.paper,
                            transition: "height 0.2s",
                            borderRadius: "5px",
                            height: "auto",
                            overflow: "hidden",
                            resize: "none",
                            "& fieldset": {
                                borderColor: `${theme.palette.background.paper} !important`,
                            },
                            "&.Mui-focused": {
                                borderColor: `${theme.palette.background.paper} !important`,
                            },
                            marginTop: "5px",
                            marginBottom: { xs: "16px", lg: "5px" },
                        }}
                    />

                    <IconButton onClick={handleSendMessage} sx={{ color: "gray" }}>
                        <SendIcon />
                    </IconButton>
                </Box>
            </Box>
        </>
    );
};

export default BottomBar;
