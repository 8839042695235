//components
import { Link } from "react-router-dom";

//material components
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import Tab from "@mui/material/Tab";
import { styled } from "@mui/material/styles";

const SytledTablist = styled(TabList)({
    "&.MuiTabs-root": {
        alignItems: "center",
    },
    "& .MuiTabs-flexContainer": {
        gap: "10px",
        alignItems: "center",
    },
    "& .MuiTabs-indicator": {
        display: "none",
    },
});

const SytledTab = styled(Tab)(({ theme }) => ({
    "&.MuiButtonBase-root": {
        backgroundColor: "#DCDCDC",
        borderRadius: "10px",
        px: "10px",
        py: "0px",
        minHeight: "35px",
        height: "35px",
        color: "#494F55",
    },
    "&.Mui-selected": {
        backgroundColor: theme.palette.primary.main,
        color: "white",
    },
}));

const NavigationChipTab = ({ links = [], pathValue }) => {
    return (
        <>
            <TabContext value={pathValue}>
                <SytledTablist variant="scrollable" scrollButtons="auto">
                    {links.map((link) => (
                        <SytledTab
                            key={link.to}
                            label={link.label}
                            to={link.to}
                            LinkComponent={Link}
                            value={link.to}
                        />
                    ))}
                </SytledTablist>
            </TabContext>
        </>
    );
};

export default NavigationChipTab;
