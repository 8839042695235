import React, { useEffect, useState } from "react";
import { PlayerContext } from "./PlayerContext";
import useFetchPlaylistFromStrapi from "../hooks/useFetchPlaylist";
import axios from "axios";

const PlayerProvider = ({ children }) => {
    const { data, error } = useFetchPlaylistFromStrapi(
        `${process.env.REACT_APP_BASE_URL}/api/reproduction-list/get-data`,
    );
    const [playList, setPlayList] = useState(null);

    // Posible api para obtener la lista de reproduccion del usuario por defecto: /reproduction-list/default
    const getPlayListDeafult = async () => {
        await axios
            .get(`${process.env.REACT_APP_BASE_URL}/api/reproduction-list/get-data`)
            .then((data) => {
                setPlayList(data?.data);
            })
            .catch((error) => {
                console.log("error context", error);
            });
    };
    const addMusic = (newMusic) => {
        setPlayList(newMusic); //aqui se cambia la lista por una nueva cuando ya se este cogiendo datos reales
    };
    useEffect(() => {
        getPlayListDeafult();
    }, []);
    return <PlayerContext.Provider value={{ playList, addMusic }}>{children}</PlayerContext.Provider>;
};

export default PlayerProvider;
