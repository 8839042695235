import * as ReactDOMClient from "react-dom/client";
import "@fontsource/roboto";
import "@fontsource/poppins";

// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// context
import { ModeProvider } from "./context/ModeProvider";
import { LanguageProvider } from "./context/LanguageProvider";
import { NotificationProvider } from "./context/NotificationProvider";
import { persistor, store } from "./redux/store";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";

// own components
import Notification from "./components/Notification/Notification";

// app
import App from "./App";

// styles
import "./index.css";

import { initializeApp } from "firebase/app";
//import { getAnalytics } from "firebase/analytics";

import { getMessaging } from "firebase/messaging";
import { getAnalytics } from "firebase/analytics";
import { FirebaseConext, FirebaseContextAnalytics } from "./context/FirebaseConext";

const container = document.getElementById("root");

// Create a root.
const root = ReactDOMClient.createRoot(container);

const firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
//const analytics = getAnalytics(app);
const messaging = getMessaging(app);
const analytics = getAnalytics(app);

root.render(
    <LanguageProvider>
        <ModeProvider>
            <FirebaseConext.Provider value={messaging}>
                <FirebaseContextAnalytics.Provider value={analytics}>
                    <NotificationProvider>
                        <Provider store={store}>
                            <PersistGate loading={null} persistor={persistor}>
                                <App />
                            </PersistGate>
                        </Provider>
                    </NotificationProvider>
                </FirebaseContextAnalytics.Provider>
            </FirebaseConext.Provider>
        </ModeProvider>
    </LanguageProvider>,
);
