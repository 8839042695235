import { createContext, useReducer, useContext } from "react";

const ActivateSearchContext = createContext(null);
const ActivateSearchDispatchContext = createContext(null);

export function ActivateSearchProvider({ children }) {
    const [activateSearch, dispatch] = useReducer(activateSearchReducer, false);
    return (
        <ActivateSearchContext.Provider value={activateSearch}>
            <ActivateSearchDispatchContext.Provider value={dispatch}>
                {children}
            </ActivateSearchDispatchContext.Provider>
        </ActivateSearchContext.Provider>
    );
}

function activateSearchReducer(activateSearch, action) {
    switch (action.type) {
        case "turnOn":
            return true;
        case "turnOff":
            return false;
        case "switch":
            return !activateSearch;
        default: {
            throw Error("Unknown action: " + action.type);
        }
    }
}

//hooks
export function useActivateSearch() {
    return useContext(ActivateSearchContext);
}

export function useDispatchActivateSearch() {
    return useContext(ActivateSearchDispatchContext);
}
