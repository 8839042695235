import React, { Fragment, useContext, useState } from "react";
import { Drawer, IconButton, Badge, useTheme, Backdrop, CircularProgress } from "@mui/material";
import { useLanguage } from "../../context/LanguageProvider";
import LocalGroceryStoreIcon from "@mui/icons-material/LocalGroceryStore";
import { CarContext } from "../../context/CarContext";
import { TicketContext } from "../../context/TicketContext";
import { CrowdFundingContext } from "../../context/CrowdFundingConext";
import { BuyingContext } from "../../context/BuyingContext";
import TabPanelCar from "./TabPanelCar";

const ShopCar = () => {
    const { languageState } = useLanguage();

    const [anchorEl, setAnchorEl] = useState(null);

    const open = Boolean(anchorEl);

    const context = useContext(CarContext);
    const contextTicket = useContext(TicketContext);
    const contextCrowdFunding = useContext(CrowdFundingContext);
    const contextBuying = useContext(BuyingContext);

    const theme = useTheme();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <Fragment>
            <Backdrop
                sx={{
                    color: "#fff",
                    zIndex: 999999,
                }}
                open={contextBuying}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <IconButton
                id="basic-button"
                color="primary"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
            >
                <Badge
                    badgeContent={context.length + contextTicket.length + contextCrowdFunding.length}
                    color="secondary"
                >
                    <LocalGroceryStoreIcon />
                </Badge>
            </IconButton>
            <Drawer
                anchor="right"
                open={open}
                onClose={handleClose}
                sx={{
                    zIndex: 999998,
                }}
            >
                <TabPanelCar />
            </Drawer>
        </Fragment>
    );
};

export default ShopCar;
