//@mui/material
import { Avatar, Box, ListItem, Typography, useTheme } from "@mui/material";

//assets/images
import HeadRobot from "../../../assets/images/cabeza_robot-removebg-preview.png";

//hooks
import { useSelector } from "react-redux";
import { useLanguage } from "../../../context/LanguageProvider";

const ConversationCloudFloatChat = ({ message, isSameSender }) => {
    const theme = useTheme();
    const { languageState } = useLanguage();
    const user = useSelector((state) => state.user);

    return (
        <ListItem sx={{ display: "flex" }}>
            {message?.role == "model" ? (
                <Avatar
                    alt=""
                    src={HeadRobot}
                    sx={{
                        marginRight: 1,
                    }}
                />
            ) : (
                <Avatar
                    alt=""
                    src={user?.avatar}
                    sx={{
                        marginRight: 1,
                    }}
                />
            )}

            <Box sx={{ height: "100%" }}>
                <Box>
                    <Typography
                        style={{
                            fontSize: "10px",
                        }}
                    >
                        {message?.role == "model"
                            ? languageState.texts.FloatChat.BotName
                            : user?.username}
                    </Typography>
                </Box>
                <Box
                    sx={{
                        borderRadius: "10px",
                        backgroundColor:
                            message?.role == "model"
                                ? theme.chat.background.conversationCloud
                                : theme.chat.background.paper,
                    }}
                >
                    <Typography
                        variant="body1"
                        style={{
                            padding: "5px",
                            fontSize: "13px",
                        }}
                    >
                        {message?.parts[0]?.text}
                    </Typography>
                </Box>
            </Box>
        </ListItem>
    );
};

export default ConversationCloudFloatChat;
