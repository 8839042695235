import { Avatar, Box, Typography, useTheme } from "@mui/material";
import React, { useState } from "react";

//icons
import ShareIcon from "@mui/icons-material/Share";
import { LoadingButton } from "@mui/lab";
import CustomSnackbar from "../../Forms/Artist/Profile/SnackBar";
import { useLanguage } from "../../../context/LanguageProvider";

const UserList = ({ user, handleSearch, bottomShared = false, item }) => {
    const theme = useTheme();
    const { languageState } = useLanguage();
    const [loading, setLoading] = useState(false);
    const [snackbar, setSnackbar] = useState(null);
    const userToken = sessionStorage.getItem("token");

    const onShared = () => {
        setLoading(true);

        const requestOptions = {
            method: "PUT",
            headers: { Authorization: `Bearer ${userToken}` },
        };
        fetch(
            `${process.env.REACT_APP_BASE_URL}/api/reproduction-list/share/${user.id}/${item.id}`,
            requestOptions,
        )
            .then((response) => response.json())
            .then(() => {
                setSnackbar({
                    children: `${languageState.texts.Player.UserList.Messages}`,
                    severity: "success",
                });
                setLoading(false);
            })
            .catch((e) => {
                setLoading(false);
                setSnackbar({
                    children: `${languageState.texts.Forms.Profile.Messages.SavedError}`,
                    severity: "error",
                });
            });
    };

    return (
        <>
            <CustomSnackbar snackbar={snackbar} setSnackbar={setSnackbar} />
            <Box
                onClick={() => handleSearch(user)}
                sx={{
                    width: "100%",
                    height: "72px",
                    padding: "4px 6px",
                    display: "flex",
                    ":hover": {
                        cursor: "pointer",
                        background: theme.palette.background.paper,
                    },
                    marginTop: "5px",
                }}
            >
                <Box sx={{ width: "10%", height: "72px", display: "flex", alignItems: "center" }}>
                    <Avatar
                        alt="user avatar"
                        src={`${process.env.REACT_APP_BASE_URL}${user.image?.url}`}
                        sx={{
                            width: { xs: "50px", lg: "50px", xl: "60px" },
                            height: { xs: "50px", lg: "50px", xl: "60px" },
                        }}
                    ></Avatar>
                </Box>
                <Box
                    sx={{
                        width: "90%",
                        height: "72px",
                        marginLeft: "15px",
                        display: "flex",
                        borderBottom: "1px solid gray",
                        flexDirection: "column",
                        textAlign: "initial",
                        ":hover": {
                            borderBottom: "none",
                        },
                    }}
                >
                    {" "}
                    <Box
                        sx={{
                            width: "100%",
                            height: "100%",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            paddingY: "10px",
                        }}
                    >
                        <Box
                            sx={{
                                width: "75%",
                                display: "flex",
                                flexDirection: "column",
                                paddingX: "4px",
                            }}
                        >
                            <Typography sx={{ fontSize: "17px", cursor: "pointer" }}>
                                {user.username}
                            </Typography>
                            <Typography sx={{ fontSize: "14px", marginTop: "5px", cursor: "pointer" }}>
                                {user?.role.name}
                            </Typography>
                        </Box>
                        {bottomShared == true ? (
                            <LoadingButton
                                onClick={onShared}
                                title={languageState.texts.Player.UserList.LoadingButton}
                                variant="text"
                                loading={loading}
                                loadingPosition="end"
                            >
                                <ShareIcon />
                            </LoadingButton>
                        ) : (
                            ""
                        )}
                    </Box>
                </Box>
            </Box>
        </>
    );
};

export default UserList;
